var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"grey--text text-body-1"},[_vm._v(" "+_vm._s(_vm.$t("kurikulum.kurikulum").toUpperCase())+" » "+_vm._s(_vm.$t("mk.daftar_mk").toUpperCase())+" » "+_vm._s(_vm.$t("mk.pengampu_mk").toUpperCase())+" ")])]),_c('br'),_vm._l((_vm.datasMK),function(dt){return _c('v-col',{key:dt.id,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-4 text-body-2",attrs:{"tile":"","elevation":"1"}},[_c('v-chip',{attrs:{"label":"","color":"primary"}},[_c('b',[_vm._v(_vm._s(dt.kode_mata_kuliah))])]),_c('table',{staticStyle:{"width":"100%","padding-top":"4pt"},attrs:{"border":"0"}},[_c('tr',[_c('td',{staticStyle:{"width":"50%"}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(dt.nama_mata_kuliah_id))])]),_c('td',{staticStyle:{"width":"25%"}},[_vm._v("Jenis mata kuliah")]),_c('td',{staticStyle:{"width":"25%"}},[_vm._v(": "+_vm._s(dt.jenis_mk.nama_jenis_mk_id))])]),_c('tr',[_c('td',[_c('i',[_vm._v(_vm._s(dt.nama_mata_kuliah_en))])]),_c('td',[_vm._v("Kategori")]),_c('td',[_vm._v(" : "),(dt.kategori_mata_kuliah == '1')?_c('span',[_vm._v(" MKK ")]):_c('span',[_vm._v("MKWU")])])]),_c('tr',[_c('td',[_vm._v(_vm._s(dt.sks_mata_kuliah)+" sks")]),_c('td'),_c('td')]),_c('tr',[_c('td',[_vm._v(" Koordinator : "),(
                    dt.koordinator == '' ||
                    dt.koordinator == 0 ||
                    dt.koordinator == null
                  )?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(dt.koordinator.nama))])]),_c('td'),_c('td')])])],1),_c('v-alert',{staticClass:"blue-grey lighten-5",attrs:{"text":"","tile":""}},[_c('h4',[_vm._v("Deskripsi Mata Kuliah")]),_c('div',{staticClass:"py-2"},[(
                dt.deskripsi_id == '' ||
                dt.deskripsi_id == 0 ||
                dt.deskripsi_id == null
              )?_c('span',[_vm._v("Belum ada deskripsi.")]):_c('span',[_vm._v(_vm._s(dt.deskripsi_id))])]),_c('v-divider'),_c('div',{staticClass:"py-2"},[_c('i',[(
                  dt.deskripsi_en == '' ||
                  dt.deskripsi_en == 0 ||
                  dt.deskripsi_en == null
                )?_c('span',[_vm._v("No description yet.")]):_c('span',[_vm._v(_vm._s(dt.deskripsi_en))])])])],1)],1)})],2)],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datas,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"item.nomor",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datas.indexOf(item) + 1)+" ")]}},{key:"item.dosen.prodi.nama_prodi_id",fn:function(ref){
                var item = ref.item;
return [(item.dosen.prodi.jenjang == 'A')?_c('span',[_vm._v(" S3 ")]):(item.dosen.prodi.jenjang == 'B')?_c('span',[_vm._v(" S2 ")]):(item.dosen.prodi.jenjang == 'C')?_c('span',[_vm._v(" S1 ")]):(item.dosen.prodi.jenjang == 'D')?_c('span',[_vm._v(" D4 ")]):(item.dosen.prodi.jenjang == 'E')?_c('span',[_vm._v(" D3 ")]):(item.dosen.prodi.jenjang == 'F')?_c('span',[_vm._v(" D2 ")]):(item.dosen.prodi.jenjang == 'G')?_c('span',[_vm._v(" D1 ")]):(item.dosen.prodi.jenjang == 'H')?_c('span',[_vm._v(" Sp-1 ")]):(item.dosen.prodi.jenjang == 'I')?_c('span',[_vm._v(" Sp-2 ")]):(item.dosen.prodi.jenjang == 'J')?_c('span',[_vm._v(" PROFESI ")]):(item.dosen.prodi.jenjang == 'X')?_c('span',[_vm._v(" NON AKADEMIK ")]):_vm._e(),_vm._v(" - "+_vm._s(item.dosen.prodi.nama_prodi_id)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("mk.pengampu_mk")))])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.dosenItems,"rules":_vm.rules,"item-value":"dosen.nidn","item-text":"dosen.nama","label":"Dosen","clearable":"","single-line":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.dosen.nidn)+" - "+_vm._s(item.dosen.nama)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.dosen.nidn)+" - "+_vm._s(item.dosen.nama)+" ")]}}]),model:{value:(_vm.editedItem.nidn),callback:function ($$v) {_vm.$set(_vm.editedItem, "nidn", $$v)},expression:"editedItem.nidn"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("tabel.batal"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":!_vm.valid,"text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("tabel.simpan"))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("tabel.dialog_hapus")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("tabel.batal")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("tabel.ok")))]),_c('v-spacer')],1)],1)],1)],1),_c('v-text-field',{staticClass:"mx-4 pt-4",attrs:{"label":_vm.$t('tabel.cari')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("tabel.tidak_ada_data"))+" ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }