<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("kurikulum.kurikulum").toUpperCase() }} &raquo;
            {{ $t("mk.daftar_mk").toUpperCase() }} &raquo;
            {{ $t("mk.detail_mk").toUpperCase() }}
          </div>
        </v-col>
        <br />
        <v-col cols="12" v-for="dt in datas" :key="dt.id">
          <v-card tile elevation="1" class="pa-4 text-body-2">
            <v-chip label color="primary"
              ><b>{{ dt.kode_mata_kuliah }}</b></v-chip
            >
            <table border="0" style="width: 100%; padding-top: 4pt">
              <tr>
                <td style="width: 50%">
                  <span class="primary--text">{{
                    dt.nama_mata_kuliah_id
                  }}</span>
                </td>
                <td style="width: 25%">Jenis mata kuliah</td>
                <td style="width: 25%">: {{ dt.jenis_mk.nama_jenis_mk_id }}</td>
              </tr>
              <tr>
                <td>
                  <i>{{ dt.nama_mata_kuliah_en }}</i>
                </td>
                <td>Kategori</td>
                <td>
                  : <span v-if="dt.kategori_mata_kuliah == '1'"> MKK </span
                  ><span v-else>MKWU</span>
                </td>
                <!-- <td>Nilai minimum lulus</td>
                <td>: {{ dt.nilai_minimum }}</td> -->
              </tr>
              <tr>
                <td>{{ dt.sks_mata_kuliah }} sks</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Koordinator :
                  <span
                    v-if="
                      dt.koordinator == '' ||
                      dt.koordinator == 0 ||
                      dt.koordinator == null
                    "
                    >-</span
                  ><span v-else>{{ dt.koordinator.nama }}</span>
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </v-card>
          <v-alert text tile class="blue-grey lighten-5 text-body-2"
            ><h4>
              Deskripsi Mata Kuliah
              <v-icon
                small
                class="mb-1"
                @click="deskripsi(dt)"
                v-if="status == false"
              >
                mdi-pencil
              </v-icon>
            </h4>
            <div class="py-2">
              <span
                v-if="
                  dt.deskripsi_id == '' ||
                  dt.deskripsi_id == 0 ||
                  dt.deskripsi_id == null
                "
                >Belum ada deskripsi.</span
              ><span v-else>{{ dt.deskripsi_id }}</span>
            </div>
            <v-divider></v-divider>
            <div class="py-2">
              <i
                ><span
                  v-if="
                    dt.deskripsi_en == '' ||
                    dt.deskripsi_en == 0 ||
                    dt.deskripsi_en == null
                  "
                  >No description yet.</span
                ><span v-else>{{ dt.deskripsi_en }}</span></i
              >
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-n6">
      <v-row>
        <v-col cols="12">
          <v-card tile :loading="loading">
            <v-tabs>
              <v-tab> CPL </v-tab>
              <v-tab> CP-MK </v-tab>
              <v-tab> Rencana Pembelajaran </v-tab>
              <v-tab> Rencana Asesmen & Evaluasi </v-tab>

              <!-- Tab CPL -->
              <v-tab-item>
                <v-card flat>
                  <v-card-text class="black--text"
                    ><b>Capaian Pembelajaran Kurikulum</b><br />
                    <v-simple-table class="table">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center"></th>
                            <th class="text-center" style="width: 100px">
                              Kode CPL
                            </th>
                            <th class="text-center">Deskripsi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in cpl" :key="item.id">
                            <td>
                              <span v-if="item.cpl_mk != null">
                                <v-checkbox
                                  v-model="item.created_by"
                                  @click="unchekCPL(item)"
                                  v-if="status == false"
                                ></v-checkbox>
                                <v-checkbox
                                  v-model="item.created_by"
                                  readonly
                                  v-else
                                ></v-checkbox>
                              </span>
                              <span v-else>
                                <v-checkbox
                                  @click="chekCPL(item)"
                                  :disabled="status"
                                ></v-checkbox>
                              </span>
                            </td>
                            <td class="text-left">
                              {{ $t("cpl.cpl_kode") }}-{{ item.kode_cpl }}
                            </td>
                            <td>
                              {{ item.cpl_id }}<br /><i>{{ item.cpl_en }}</i>
                            </td>
                          </tr>
                          <tr v-for="item in cplMKWU" :key="item.id">
                            <td>
                              <span v-if="item.cpl_mk != null">
                                <v-checkbox
                                  v-model="item.created_by"
                                  @click="unchekCPL(item)"
                                  v-if="status == false"
                                ></v-checkbox>
                                <v-checkbox
                                  v-model="item.created_by"
                                  readonly
                                  v-else
                                ></v-checkbox>
                              </span>
                              <span v-else>
                                <v-checkbox
                                  @click="chekCPL(item)"
                                  :disabled="status"
                                ></v-checkbox>
                              </span>
                            </td>
                            <td class="text-left">
                              {{ $t("cpl.cpl_kode") }}-{{ item.kode_cpl }}
                            </td>
                            <td>
                              {{ item.cpl_id }}<br /><i>{{ item.cpl_en }}</i>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- Tab CPMK -->
              <v-tab-item>
                <v-card flat>
                  <v-form
                    ref="formTambahCPMK"
                    v-model="validTambahCPMK"
                    lazy-validation
                    ><v-card-text class="black--text"
                      ><b>Capaian Pembelajaran Mata Kuliah</b><br />
                      <v-simple-table class="table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center" rowspan="2" width="130px">
                                Kode
                              </th>
                              <th class="text-center" rowspan="2">
                                Deskripsi CP-MK
                              </th>
                              <th
                                class="text-center"
                                :colspan="cplmk.length"
                                v-if="cplmk.length != 0"
                              >
                                Pemetaan CP-MK ke CPL
                              </th>
                              <th class="text-center" rowspan="2" width="100px">
                                Bobot CP-MK
                              </th>
                              <th
                                class="text-center"
                                rowspan="2"
                                width="130px"
                                :hidden="status"
                              >
                                Aksi
                              </th>
                            </tr>
                            <tr>
                              <th
                                class="text-center"
                                v-for="dt in cplmk"
                                :key="dt.id"
                                width="80px"
                              >
                                <v-tooltip bottom color="info" max-width="20%">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                      v-bind="attrs"
                                      v-on="on"
                                      class="blue--text"
                                      >{{ $t("cpl.cpl_kode") }}-{{
                                        dt.cpl.kode_cpl
                                      }}</span
                                    >
                                  </template>
                                  <span
                                    ><b>Deskripsi:</b><br />{{
                                      dt.cpl.cpl_id
                                    }}</span
                                  >
                                </v-tooltip>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="n in cpmk" :key="n.id" class="mt-5">
                              <td class="text-center">
                                <v-text-field
                                  v-if="isEditing == n.id"
                                  v-model="n.kode_cpmk"
                                  prefix="CPMK-"
                                  outlined
                                  solo
                                  dense
                                  :rules="rules"
                                ></v-text-field>
                                <span v-else> CPMK-{{ n.kode_cpmk }} </span>
                              </td>
                              <td class="text-left">
                                <v-textarea
                                  v-if="isEditing == n.id"
                                  v-model="n.cpmk_id"
                                  outlined
                                  solo
                                  :rules="rules"
                                  dense
                                  rows="3"
                                  class="mb-n6 mt-1"
                                  label="Deskripsi CP-MK"
                                ></v-textarea>
                                <span v-else>{{ n.cpmk_id }} <br /></span>
                                <v-textarea
                                  v-if="isEditing == n.id"
                                  v-model="n.cpmk_en"
                                  outlined
                                  solo
                                  :rules="rules"
                                  dense
                                  rows="3"
                                  class="mb-n6 mt-1"
                                  label="Deskripsi CP-MK (Inggris)"
                                ></v-textarea>
                                <span v-else
                                  ><i>{{ n.cpmk_en }}</i></span
                                >
                              </td>
                              <td
                                class="text-center"
                                v-for="dt in cplmk"
                                :key="dt.id"
                              >
                                <span
                                  v-if="
                                    cpmkcpl.filter(
                                      (e) =>
                                        e.cpl_id === dt.cpl_id &&
                                        e.cpmk_id === n.id
                                    ).length > 0
                                  "
                                >
                                  <v-checkbox
                                    :input-value="n.id + '-' + dt.cpl_id"
                                    class="d-inline-flex"
                                    @click="
                                      unchekCPMKCPL(n.id + '-' + dt.cpl_id)
                                    "
                                    v-if="
                                      status == false && statusCPMKCPL == false
                                    "
                                  ></v-checkbox>
                                  <v-checkbox
                                    :input-value="n.id + '-' + dt.cpl_id"
                                    class="d-inline-flex"
                                    readonly
                                    v-else
                                  ></v-checkbox>
                                </span>
                                <span v-else>
                                  <v-checkbox
                                    class="d-inline-flex"
                                    @click="chekCPMKCPL(n.id + '-' + dt.cpl_id)"
                                    :disabled="status || statusCPMKCPL"
                                  ></v-checkbox>
                                </span>
                              </td>
                              <td class="text-center">
                                <v-text-field
                                  v-if="isEditing == n.id"
                                  v-model="n.bobot_cpmk"
                                  type="number"
                                  suffix="%"
                                  outlined
                                  dense
                                  solo
                                  :rules="rules"
                                  class="mt-6 inputPrice"
                                ></v-text-field>
                                <v-text-field
                                  v-else
                                  v-model="n.bobot_cpmk"
                                  type="number"
                                  suffix="%"
                                  outlined
                                  dense
                                  :rules="rules"
                                  class="mt-6 inputPrice"
                                  readonly
                                  filled
                                ></v-text-field>
                              </td>
                              <td class="text-center" :hidden="status">
                                <v-btn
                                  v-if="isEditing == n.id"
                                  elevation="2"
                                  fab
                                  color="warning"
                                  small
                                  @click="editCPMK(n)"
                                  ><v-icon small class="white--text">
                                    mdi-content-save
                                  </v-icon></v-btn
                                >
                                &nbsp;
                                <v-btn
                                  v-if="isEditing == n.id"
                                  elevation="2"
                                  class="white--text"
                                  color="error"
                                  fab
                                  small
                                  @click="isEditing = null"
                                >
                                  <v-icon
                                    @click="getCpmkByKodeMk"
                                    small
                                    class="white--text"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="isEditing == null"
                                  elevation="2"
                                  class="white--text"
                                  color="warning"
                                  fab
                                  small
                                  @click="isEditing = n.id"
                                >
                                  <v-icon small class="white--text">
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                                &nbsp;
                                <v-btn
                                  v-if="isEditing == null"
                                  elevation="2"
                                  fab
                                  color="error"
                                  small
                                  @click="hapusCPMK(n.id)"
                                  ><v-icon small class="white--text">
                                    mdi-delete
                                  </v-icon></v-btn
                                >
                              </td>
                            </tr>
                            <tr
                              class="mt-5"
                              :hidden="status"
                              v-if="isTambahCPMK"
                            >
                              <td class="text-center">
                                <v-text-field
                                  v-model="tambah_kode_cpmk"
                                  prefix="CPMK-"
                                  outlined
                                  dense
                                  :rules="rules"
                                ></v-text-field>
                              </td>
                              <td class="text-left">
                                <v-textarea
                                  v-model="tambah_cpmk_id"
                                  outlined
                                  :rules="rules"
                                  dense
                                  rows="3"
                                  class="mb-n6 mt-1"
                                  label="Deskripsi CP-MK"
                                ></v-textarea>
                                <v-textarea
                                  v-model="tambah_cpmk_en"
                                  outlined
                                  :rules="rules"
                                  dense
                                  rows="3"
                                  class="mb-n6 mt-1"
                                  label="Deskripsi CP-MK (Inggris)"
                                ></v-textarea>
                              </td>
                              <td
                                class="text-center"
                                v-for="dt in cplmk"
                                :key="dt.id"
                              ></td>
                              <td class="text-center">
                                <v-text-field
                                  v-model="tambah_bobot_cpmk"
                                  type="number"
                                  suffix="%"
                                  outlined
                                  dense
                                  :rules="rules"
                                  class="mt-6 inputPrice"
                                ></v-text-field>
                              </td>
                              <td class="text-center">
                                <v-btn
                                  elevation="2"
                                  fab
                                  color="blue"
                                  small
                                  :disabled="!validTambahCPMK"
                                  @click="tambahCPMK()"
                                  ><v-icon small class="white--text">
                                    mdi-content-save
                                  </v-icon></v-btn
                                >
                              </td>
                            </tr>
                            <tr :hidden="status">
                              <td align="center">
                                <v-btn
                                  class="white--text"
                                  color="primary"
                                  fab
                                  small
                                  @click="isTambahCPMK = !isTambahCPMK"
                                >
                                  <v-icon v-if="isTambahCPMK" color="white">
                                    mdi-close
                                  </v-icon>
                                  <v-icon v-else color="white">
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </td>
                            </tr>
                            <tr style="background-color: lightgrey">
                              <td
                                colspan="2"
                                class="text-center font-weight-bold"
                              >
                                Total Bobot
                              </td>
                              <td
                                v-for="dt in cplmk"
                                :key="dt.id"
                                class="text-center font-weight-bold"
                              >
                                <span v-if="cpmkcpl.length == 0"> 0 </span>
                                <span v-else>
                                  {{
                                    Math.round(
                                      (cpmkcpl.filter(
                                        (e) => e.cpl_id === dt.cpl_id
                                      ).length /
                                        totalBobotCPL) *
                                        100
                                    )
                                  }}
                                </span>
                                %
                              </td>
                              <td class="text-center font-weight-bold">
                                <span v-if="totalBobot == 100">
                                  {{ totalBobot }} %
                                </span>
                                <span v-else class="red--text">
                                  {{ totalBobot }} %
                                </span>
                              </td>
                              <td
                                class="text-center font-weight-bold"
                                :hidden="status"
                              ></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-tab-item>
              <!-- Tab RPS -->
              <v-tab-item>
                <v-card flat>
                  <v-form
                    ref="formTambahRPS"
                    v-model="validTambahRPS"
                    lazy-validation
                    ><v-card-text class="black--text"
                      ><b>Rencana Pembelajaran Semester</b><br />
                      <v-simple-table class="table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" width="100px">
                                Minggu ke-
                              </th>
                              <th class="text-left">Materi Perkuliahan</th>
                              <th class="text-left" width="350px">
                                Metode Pembelajaran
                              </th>
                              <th
                                class="text-center"
                                width="130px"
                                :hidden="status"
                              >
                                Aksi
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="rp in rps" :key="rp.id" class="mt-5">
                              <td class="text-center">
                                <v-text-field
                                  v-if="isEditingRPS == rp.id"
                                  v-model="rp.minggu"
                                  outlined
                                  dense
                                  solo
                                  :rules="rules"
                                ></v-text-field>
                                <span v-else>
                                  {{ rp.minggu }}
                                </span>
                              </td>
                              <td class="text-left">
                                <v-textarea
                                  v-if="isEditingRPS == rp.id"
                                  v-model="rp.materi_id"
                                  outlined
                                  :rules="rules"
                                  dense
                                  solo
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Materi Perkuliahan"
                                ></v-textarea>
                                <span v-else>{{ rp.materi_id }}<br /></span>
                                <v-textarea
                                  v-if="isEditingRPS == rp.id"
                                  v-model="rp.materi_en"
                                  outlined
                                  :rules="rules"
                                  dense
                                  solo
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Materi Perkuliahan (Inggris)"
                                ></v-textarea>
                                <span v-else
                                  ><i>{{ rp.materi_en }}</i></span
                                >
                              </td>
                              <td class="text-left">
                                <span v-if="isEditingRPS == rp.id">
                                  <v-select
                                    v-model="rp.metode_pembelajaran_id"
                                    :items="metodePembelajaranItems"
                                    :rules="rules"
                                    item-value="id"
                                    item-text="nama_metode_id"
                                    label="Pilih Metode"
                                    dense
                                    solo
                                    outlined
                                    class="mt-6"
                                  ></v-select>
                                  <v-text-field
                                    v-if="rp.metode_pembelajaran_id == 3"
                                    v-model="rp.keterangan"
                                    outlined
                                    dense
                                    solo
                                    :rules="rules"
                                    label="Masukkan Metode SCL Lainnya"
                                  ></v-text-field>
                                </span>
                                <span v-else>
                                  <span v-if="rp.metode_pembelajaran_id == 3"
                                    ><small>{{
                                      rp.metode_pembelajaran.nama_metode_id
                                    }}</small></span
                                  >
                                  <span v-else>{{
                                    rp.metode_pembelajaran.nama_metode_id
                                  }}</span>
                                  <br />
                                  <span v-if="rp.metode_pembelajaran_id == 3">{{
                                    rp.keterangan
                                  }}</span>
                                </span>
                              </td>
                              <td class="text-center" :hidden="status">
                                <v-btn
                                  v-if="isEditingRPS == rp.id"
                                  elevation="2"
                                  fab
                                  color="warning"
                                  small
                                  @click="editRPS(rp)"
                                  ><v-icon small class="white--text">
                                    mdi-content-save
                                  </v-icon></v-btn
                                >
                                &nbsp;
                                <v-btn
                                  v-if="isEditingRPS == rp.id"
                                  elevation="2"
                                  class="white--text"
                                  color="error"
                                  fab
                                  small
                                  @click="isEditingRPS = null"
                                >
                                  <v-icon
                                    @click="getRencanaPembelajaranByKodeMk"
                                    small
                                    class="white--text"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="isEditingRPS == null"
                                  elevation="2"
                                  class="white--text"
                                  color="warning"
                                  fab
                                  small
                                  @click="isEditingRPS = rp.id"
                                >
                                  <v-icon small class="white--text">
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                                &nbsp;
                                <v-btn
                                  v-if="isEditingRPS == null"
                                  elevation="2"
                                  fab
                                  color="error"
                                  small
                                  @click="hapusRPS(rp.id)"
                                  ><v-icon small class="white--text">
                                    mdi-delete
                                  </v-icon></v-btn
                                >
                              </td>
                            </tr>
                            <tr
                              class="mt-5"
                              :hidden="status"
                              v-if="isTambahRPS"
                            >
                              <td class="text-center">
                                <v-text-field
                                  v-model="tambah_minggu"
                                  outlined
                                  dense
                                  :rules="rules"
                                ></v-text-field>
                              </td>
                              <td class="text-left">
                                <v-textarea
                                  v-model="tambah_materi_id"
                                  outlined
                                  :rules="rules"
                                  dense
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Materi Perkuliahan"
                                ></v-textarea>
                                <v-textarea
                                  v-model="tambah_materi_en"
                                  outlined
                                  :rules="rules"
                                  dense
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Materi Perkuliahan (Inggris)"
                                ></v-textarea>
                              </td>
                              <td class="text-center">
                                <v-select
                                  v-model="tambah_metode_pembelajaran_id"
                                  :items="metodePembelajaranItems"
                                  :rules="rules"
                                  item-value="id"
                                  item-text="nama_metode_id"
                                  label="Pilih Metode"
                                  dense
                                  outlined
                                  class="mt-6"
                                ></v-select>
                                <v-text-field
                                  v-if="tambah_metode_pembelajaran_id == 3"
                                  v-model="tambah_keterangan"
                                  outlined
                                  dense
                                  :rules="rules"
                                  label="Masukkan Metode SCL Lainnya"
                                ></v-text-field>
                              </td>
                              <td class="text-center">
                                <v-btn
                                  elevation="2"
                                  fab
                                  color="blue"
                                  small
                                  :disabled="!validTambahRPS"
                                  @click="tambahRPS()"
                                  ><v-icon small class="white--text">
                                    mdi-content-save
                                  </v-icon></v-btn
                                >
                              </td>
                            </tr>
                            <tr :hidden="status">
                              <td align="center">
                                <v-btn
                                  class="white--text"
                                  color="primary"
                                  fab
                                  small
                                  @click="isTambahRPS = !isTambahRPS"
                                >
                                  <v-icon v-if="isTambahRPS" color="white">
                                    mdi-close
                                  </v-icon>
                                  <v-icon v-else color="white">
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-tab-item>
              <!-- Tab RAE -->
              <v-tab-item>
                <v-card flat>
                  <v-form
                    ref="formTambahRAE"
                    v-model="validTambahRAE"
                    lazy-validation
                    ><v-card-text class="black--text"
                      ><b>Rencana Asesmen & Evaluasi</b><br />
                      <v-simple-table class="table">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">No.</th>
                              <th class="text-left">Rencana Evaluasi</th>
                              <th class="text-left" style="width: 20%">
                                Deskripsi
                              </th>
                              <th
                                class="text-center"
                                v-for="cp in cpmk"
                                :key="cp.id"
                                width="90px"
                              >
                                <v-tooltip bottom color="info" max-width="20%">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                      v-bind="attrs"
                                      v-on="on"
                                      class="blue--text"
                                      >CPMK-{{ cp.kode_cpmk }}</span
                                    >
                                  </template>
                                  <span
                                    ><b>Deskripsi:</b><br />{{
                                      cp.cpmk_id
                                    }}</span
                                  >
                                </v-tooltip>
                              </th>
                              <th class="text-center" width="100px">
                                Total Bobot
                              </th>
                              <th
                                class="text-center"
                                width="130px"
                                :hidden="status"
                              >
                                Aksi
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="r in rae" :key="r.id" class="mt-5">
                              <td class="text-center">
                                {{ rae.indexOf(r) + 1 }}
                              </td>
                              <td class="text-left">
                                <v-textarea
                                  v-if="isEditingRAE == r.id"
                                  v-model="r.rae_id"
                                  outlined
                                  :rules="rules"
                                  dense
                                  solo
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Name Evaluasi"
                                ></v-textarea>
                                <span v-else>{{ r.rae_id }}<br /></span>
                                <v-textarea
                                  v-if="isEditingRAE == r.id"
                                  v-model="r.rae_en"
                                  outlined
                                  :rules="rules"
                                  dense
                                  solo
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Evaluation Name"
                                ></v-textarea>
                                <span v-else
                                  ><i>{{ r.rae_en }}</i
                                  ><br
                                /></span>
                                <span v-if="isEditingRAE == r.id">
                                  <v-select
                                    v-model="r.jenis_evaluasi_id"
                                    :items="jenisEvaluasiItems"
                                    :rules="rules"
                                    item-value="id"
                                    item-text="nama_evaluasi_id"
                                    label="Pilih Jenis Evaluasi"
                                    dense
                                    solo
                                    outlined
                                    class="mb-n6 mt-1"
                                  ></v-select>
                                  <!-- <v-text-field
                                    v-if="r.jenis_evaluasi_id == 3"
                                    v-model="r.keterangan"
                                    outlined
                                    dense
                                    solo
                                    :rules="rules"
                                    label="Masukkan Proyek"
                                    class="mb-n6 mt-1"
                                  ></v-text-field> -->
                                </span>
                                <!-- <span v-else>
                                  <span v-if="r.jenis_evaluasi_id == 3"
                                    >{{ r.jenis_evaluasi.nama_evaluasi_id }} ({{
                                      r.keterangan
                                    }})
                                  </span>
                                  <span v-else>
                                    {{
                                    r.jenis_evaluasi.nama_evaluasi_id
                                  }}</span>
                                </span> -->
                                <span v-else>{{
                                  r.jenis_evaluasi.nama_evaluasi_id
                                }}</span>
                              </td>
                              <td>
                                <read-more
                                  more-str="read more"
                                  :text="r.jenis_evaluasi.deskripsi"
                                  link="#"
                                  less-str="read less"
                                  :max-chars="50"
                                ></read-more>
                              </td>
                              <td
                                class="text-center"
                                v-for="cp in cpmk"
                                :key="cp.id"
                              >
                                <span
                                  v-if="
                                    raecpmk.filter(
                                      (e) =>
                                        e.rae.id === r.id && e.cpmk.id === cp.id
                                    ).length > 0
                                  "
                                >
                                  <span v-for="rc in raecpmk" :key="rc.id">
                                    <span
                                      v-if="
                                        rc.rae.id === r.id &&
                                        rc.cpmk.id === cp.id
                                      "
                                    >
                                      <v-text-field
                                        v-if="isEditingRAECPMK == rc.id"
                                        v-model.number="rc.bobot_rae_cpmk"
                                        :disabled="status"
                                        type="number"
                                        min="0"
                                        suffix="%"
                                        outlined
                                        dense
                                        solo
                                        class="mt-6 inputPrice"
                                        @blur="
                                          updateRAECPMK(
                                            r.id +
                                              '-' +
                                              cp.id +
                                              '-' +
                                              rc.bobot_rae_cpmk +
                                              '-' +
                                              rc.id
                                          )
                                        "
                                      ></v-text-field>
                                      <v-tooltip
                                        bottom
                                        v-else
                                        :disabled="status"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model.number="rc.bobot_rae_cpmk"
                                            suffix="%"
                                            outlined
                                            dense
                                            class="mt-6 inputPrice"
                                            @dblclick="isEditingRAECPMK = rc.id"
                                            readonly
                                            filled
                                            v-if="status == false"
                                          ></v-text-field>
                                          <v-text-field
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model.number="rc.bobot_rae_cpmk"
                                            suffix="%"
                                            outlined
                                            dense
                                            class="mt-6 inputPrice"
                                            readonly
                                            filled
                                            v-else
                                          ></v-text-field>
                                        </template>
                                        <span
                                          >Klik 2 kali untuk melakukan
                                          edit</span
                                        >
                                      </v-tooltip>
                                    </span>
                                  </span>
                                </span>
                                <span v-else>
                                  <v-text-field
                                    v-model="cp[r.id]"
                                    :disabled="status"
                                    type="number"
                                    suffix="%"
                                    min="0"
                                    outlined
                                    dense
                                    class="mt-6 inputPrice"
                                    @blur="
                                      insertRAECPMK(
                                        r.id + '-' + cp.id + '-' + cp[r.id]
                                      )
                                    "
                                  ></v-text-field>
                                </span>
                              </td>
                              <td class="text-center">
                                <v-text-field
                                  :value="
                                    raecpmk
                                      .filter((e) => e.rae.id === r.id)
                                      .reduce((accumulator, object) => {
                                        return (
                                          accumulator + object.bobot_rae_cpmk
                                        );
                                      }, 0)
                                  "
                                  suffix="%"
                                  dense
                                  filled
                                  class="mt-6 inputPrice"
                                  readonly
                                  single-line
                                  outlined
                                ></v-text-field>
                              </td>
                              <td class="text-center" :hidden="status">
                                <v-btn
                                  v-if="isEditingRAE == r.id"
                                  elevation="2"
                                  fab
                                  color="warning"
                                  small
                                  @click="editRAE(r)"
                                  ><v-icon small class="white--text">
                                    mdi-content-save
                                  </v-icon></v-btn
                                >
                                &nbsp;
                                <v-btn
                                  v-if="isEditingRAE == r.id"
                                  elevation="2"
                                  class="white--text"
                                  color="error"
                                  fab
                                  small
                                  @click="isEditingRAE = null"
                                >
                                  <v-icon
                                    @click="getRaeByKodeMk"
                                    small
                                    class="white--text"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="isEditingRAE == null"
                                  elevation="2"
                                  class="white--text"
                                  color="warning"
                                  fab
                                  small
                                  @click="isEditingRAE = r.id"
                                >
                                  <v-icon small class="white--text">
                                    mdi-pencil
                                  </v-icon>
                                </v-btn>
                                &nbsp;
                                <v-btn
                                  v-if="isEditingRAE == null"
                                  elevation="2"
                                  fab
                                  color="error"
                                  small
                                  @click="hapusRAE(r.id)"
                                  ><v-icon small class="white--text">
                                    mdi-delete
                                  </v-icon></v-btn
                                >
                              </td>
                            </tr>
                            <tr
                              class="mt-5"
                              :hidden="status"
                              v-if="isTambahRAE"
                            >
                              <td class="text-center"></td>
                              <td class="text-left" colspan="2">
                                <v-textarea
                                  v-model="tambah_rae_id"
                                  outlined
                                  :rules="rules"
                                  dense
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Name Evaluasi"
                                ></v-textarea>
                                <v-textarea
                                  v-model="tambah_rae_en"
                                  outlined
                                  :rules="rules"
                                  dense
                                  rows="2"
                                  class="mb-n6 mt-1"
                                  label="Evaluation Name"
                                ></v-textarea>
                                <v-select
                                  v-model="tambah_jenis_evaluasi_id"
                                  :items="jenisEvaluasiItems"
                                  :rules="rules"
                                  item-value="id"
                                  item-text="nama_evaluasi_id"
                                  label="Pilih Jenis Evaluasi"
                                  dense
                                  outlined
                                  class="mb-n6 mt-1"
                                ></v-select>
                                <!-- <v-text-field
                                  v-if="tambah_jenis_evaluasi_id == 3"
                                  v-model="tambah_keterangan_rae"
                                  outlined
                                  dense
                                  :rules="rules"
                                  label="Masukkan Proyek"
                                  class="mb-n6 mt-1"
                                ></v-text-field> -->
                              </td>
                              <td
                                class="text-center"
                                v-for="cp in cpmk"
                                :key="cp.id"
                              ></td>
                              <td class="text-center"></td>
                              <td class="text-center">
                                <v-btn
                                  elevation="2"
                                  fab
                                  color="blue"
                                  small
                                  :disabled="!validTambahRAE"
                                  @click="tambahRAE()"
                                  ><v-icon small class="white--text">
                                    mdi-content-save
                                  </v-icon></v-btn
                                >
                              </td>
                            </tr>
                            <tr :hidden="status">
                              <td align="center">
                                <v-btn
                                  class="white--text"
                                  color="primary"
                                  fab
                                  small
                                  @click="isTambahRAE = !isTambahRAE"
                                >
                                  <v-icon v-if="isTambahRAE" color="white">
                                    mdi-close
                                  </v-icon>
                                  <v-icon v-else color="white">
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </td>
                            </tr>
                            <tr style="background-color: lightgrey">
                              <td
                                colspan="3"
                                class="text-center font-weight-bold"
                              >
                                <b>TOTAL</b><br />
                                <i>Target</i>
                              </td>
                              <td
                                v-for="cp in cpmk"
                                :key="cp.id"
                                class="text-center font-weight-bold"
                              >
                                <span
                                  v-if="
                                    raecpmk
                                      .filter((e) => e.cpmk.id === cp.id)
                                      .reduce((accumulator, object) => {
                                        return (
                                          accumulator + object.bobot_rae_cpmk
                                        );
                                      }, 0) > cp.bobot_cpmk
                                  "
                                  class="red--text"
                                >
                                  {{
                                    raecpmk
                                      .filter((e) => e.cpmk.id === cp.id)
                                      .reduce((accumulator, object) => {
                                        return (
                                          accumulator + object.bobot_rae_cpmk
                                        );
                                      }, 0)
                                  }}
                                  %
                                </span>
                                <span
                                  v-else-if="
                                    raecpmk
                                      .filter((e) => e.cpmk.id === cp.id)
                                      .reduce((accumulator, object) => {
                                        return (
                                          accumulator + object.bobot_rae_cpmk
                                        );
                                      }, 0) < cp.bobot_cpmk
                                  "
                                  class="orange--text"
                                >
                                  {{
                                    raecpmk
                                      .filter((e) => e.cpmk.id === cp.id)
                                      .reduce((accumulator, object) => {
                                        return (
                                          accumulator + object.bobot_rae_cpmk
                                        );
                                      }, 0)
                                  }}
                                  %
                                </span>
                                <span v-else>
                                  {{
                                    raecpmk
                                      .filter((e) => e.cpmk.id === cp.id)
                                      .reduce((accumulator, object) => {
                                        return (
                                          accumulator + object.bobot_rae_cpmk
                                        );
                                      }, 0)
                                  }}
                                  %
                                </span>
                                <br />
                                <i>{{ cp.bobot_cpmk }}</i>
                                %
                              </td>
                              <td class="text-center font-weight-bold">
                                <span
                                  v-if="totalBobotRAE > totalBobot"
                                  class="red--text"
                                >
                                  {{ totalBobotRAE }} %
                                </span>
                                <span
                                  v-else-if="totalBobotRAE < totalBobot"
                                  class="orange--text"
                                >
                                  {{ totalBobotRAE }} %
                                </span>
                                <span v-else> {{ totalBobotRAE }} % </span>
                                <br />
                                <i>{{ totalBobot }}</i>
                                %
                              </td>
                              <td
                                class="text-center font-weight-bold"
                                :hidden="status"
                              ></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Tombol simpan permanen -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card
            tile
            elevation="1"
            class="pa-4 text-body-2"
            v-if="statusTombol == 0"
          >
            <span
              v-if="
                totalBobot == 100 &&
                totalBobotRAE == 100 &&
                raecpmk.length == rae.length * cpmk.length
              "
              :hidden="status"
            >
              <v-btn color="success" tile block @click="dialogPermanen = true"
                ><v-icon left>mdi-lock</v-icon> Simpan Permanen</v-btn
              >
            </span>
            <span v-else>
              <v-btn color="success" disabled tile block
                ><v-icon left>mdi-lock</v-icon> Simpan Permanen</v-btn
              >
              <span style="color: red"
                >*Lihat kembali tab CP-MK dan RAE, pastikan semua Total Bobot
                berwarna hitam dan semua form RAE terisi dengan angka.</span
              ></span
            >
          </v-card>
          <v-card tile elevation="1" class="pa-4 mt-n2" v-else>
            <v-alert outlined type="success" class="text-body-2 mb-n3" text>
              Dipermanenkan oleh <b>{{ namaDosen }}</b> pada
              {{ tglPermanen | moment("dddd, DD MMMM YYYY, H:mm") }}
              WITA. </v-alert
            ><br />
            <v-btn
              v-if="user.data.aktif.role != '5'"
              color="error"
              tile
              block
              @click="dialogPermanenBatal = true"
              ><v-icon left>mdi-lock-open-variant</v-icon> Batalkan
              Permanen</v-btn
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br /><br />

    <v-dialog v-model="dialogDeskripsi" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Deskripsi Mata Kuliah</span>
        </v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="deskripsiItem.deskripsi_id"
                    :rules="rules"
                    label="Deskripsi Mata Kuliah"
                    rows="5"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="deskripsiItem.deskripsi_en"
                    :rules="rules"
                    label="Deskripsi Mata Kuliah (Inggris)"
                    rows="5"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDeskripsi">
              {{ $t("tabel.batal") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              text
              @click="saveDeskripsi(deskripsiItem)"
            >
              {{ $t("tabel.simpan") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPermanen" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="justify-center">
          <span class="headline font-weight-medium">Apakah Anda yakin?</span>
        </v-card-title>
        <v-card-text class="text-center font-weight-medium">
          Mata Kuliah akan dipermanenkan!
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="success" @click="simpanPermanen"> Permanenkan </v-btn>
          <v-btn
            color="grey darken-1"
            class="white--text"
            @click="dialogPermanen = false"
          >
            {{ $t("tabel.batal") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPermanenBatal" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="justify-center">
          <span class="headline font-weight-medium">Apakah Anda yakin?</span>
        </v-card-title>
        <v-card-text class="text-center font-weight-medium">
          Status permanen Mata Kuliah akan dibatalkan!
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="error" @click="simpanPermanenBatal">
            Batalkan Permanen
          </v-btn>
          <v-btn
            color="grey darken-1"
            class="white--text"
            @click="dialogPermanenBatal = false"
          >
            {{ $t("tabel.batal") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout" top right color="success">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MataKuliahDetail",
  data: () => ({
    isEditing: null,
    isEditingRPS: null,
    isEditingRAE: null,
    isEditingRAECPMK: null,
    isTambahCPMK: null,
    isTambahRPS: null,
    isTambahRAE: null,
    datas: [],
    dialogDeskripsi: false,
    dialogPermanen: false,
    dialogPermanenBatal: false,
    deskripsiItem: {},
    valid: true,
    validTambahCPMK: true,
    validTambahRPS: true,
    validTambahRAE: true,
    loading: false,
    tab: null,
    cpl: [],
    cplMKWU: [],
    cplmk: [],
    cpmk: [],
    cpmkcpl: [],
    rps: [],
    rae: [],
    raecpmk: [],
    checkbox: [],
    check: [],
    jenisEvaluasiItems: [],
    metodePembelajaranItems: [],
    snackbar: false,
    text: "Berhasil Disimpan!",
    timeout: 2000,
    tambah_kode_cpmk: "",
    tambah_cpmk_id: "",
    tambah_cpmk_en: "",
    tambah_bobot_cpmk: "",
    tambah_metode_pembelajaran_id: "",
    tambah_keterangan: "",
    tambah_keterangan_rae: "",
    tambah_minggu: "",
    tambah_materi_id: "",
    tambah_materi_en: "",
    tambah_jenis_evaluasi_id: "",
    tambah_rae_id: "",
    tambah_rae_en: "",
    value: 0,
    totalBobotCPL: 0,
    totalBobot: 0,
    totalBobotRAE: 0,
    status: false,
    statusCPMKCPL: false,
    statusTombol: 0,
    namaDosen: "",
    tglPermanen: "",
    kategori_mk: "",
    koorMK: "",
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    rules() {
      return [(v) => !!v || ""];
    },
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getMataKuliah();
      this.getCplByProdiMk();
      this.getCplMkByKodeMk();
      this.getCplByKodeMkWu();
      this.getCpmkByKodeMk();
      this.getCpmkCplByKodeMk();
      this.jenisEvaluasiList();
      this.getRencanaPembelajaranByKodeMk();
      this.metodePembelajaranList();
      this.getRaeByKodeMk();
      this.getRaeCpmkByKodeMk();
    },

    getMataKuliah() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getMataKuliah/" + this.$route.params.id, config)
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
          this.kategori_mk = response.data.data[0].kategori_mata_kuliah;
          this.statusTombol = response.data.data[0].status;
          this.koorMK = response.data.data[0].koordinator;
          // console.log(this.datas);
          // console.log(this.koorMK);

          if (this.statusTombol == 0) {
            if (this.user.data.aktif.role == "5") {
              if (this.koorMK == "" || this.koorMK == null) {
                this.status = true;
              } else {
                if (
                  response.data.data[0].koordinator.nidn ==
                  this.user.data.username
                ) {
                  this.status = false;
                } else {
                  this.status = true;
                }
              }
            } else {
              this.status = false;
              if (this.kategori_mk == 2) {
                this.statusCPMKCPL = true;
              } else {
                this.statusCPMKCPL = false;
              }
            }
          } else {
            this.status = true;
            this.namaDosen = response.data.data[0].log.created_by.nama;
            this.tglPermanen = response.data.data[0].log.created_at;
          }
          // console.log(this.status);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getCplByProdiMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getCplByProdiMk/" +
            this.user.data.aktif.kode_prodi +
            "/" +
            this.$route.params.id,
          config
        )
        .then((response) => {
          // this.$vloading.hide();
          this.cpl = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getCplByKodeMkWu() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getCplByKodeMkWu/" + this.$route.params.id, config)
        .then((response) => {
          this.cplMKWU = response.data.data;
        })
        .catch((error) => {
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getCplMkByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getCplMkByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          this.cplmk = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getCpmkByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getCpmkByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          this.totalBobot = 0;
          this.cpmk = response.data.data;
          this.cpmk.forEach((element) => {
            this.totalBobot += parseInt(element.bobot_cpmk);
          });
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getCpmkCplByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getCpmkCplByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          this.cpmkcpl = response.data.data;
          this.totalBobotCPL = parseInt(this.cpmkcpl.length);
          // console.log(this.totalBobotCPL);
        })
        .catch(() => {
          this.cpmkcpl = [];
          // this.$vloading.hide();
          // this.setAlert({
          //   status: true,
          //   color: "error",
          //   text: error.response.data.message,
          // });
        });
    },

    jenisEvaluasiList() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/jenisEvaluasiList", config)
        .then((response) => {
          // this.$vloading.hide();
          this.jenisEvaluasiItems = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getRencanaPembelajaranByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getRencanaPembelajaranByKodeMk/" + this.$route.params.id,
          config
        )
        .then((response) => {
          // this.$vloading.hide();
          this.rps = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    metodePembelajaranList() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/metodePembelajaranList", config)
        .then((response) => {
          // this.$vloading.hide();
          this.metodePembelajaranItems = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getRaeByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getRaeByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          // this.totalBobot = 0;
          this.rae = response.data.data;
          // this.rae.forEach((element) => {
          //   this.totalBobot += parseInt(element.bobot_rae);
          // });
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getRaeCpmkByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getRaeCpmkByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          this.totalBobotRAE = 0;
          this.raecpmk = response.data.data;
          if (this.raecpmk.length > 0) {
            this.raecpmk.forEach((element) => {
              this.totalBobotRAE += parseInt(element.bobot_rae_cpmk);
            });
          }
          // console.log(this.raecpmk);
        })
        .catch(() => {
          this.raecpmk = [];
          // this.$vloading.hide();
          // this.setAlert({
          //   status: true,
          //   color: "error",
          //   text: error.response.data.message,
          // });
        });
    },

    deskripsi(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.deskripsiItem = Object.assign({}, item);
      this.dialogDeskripsi = true;
    },

    closeDeskripsi() {
      this.dialogDeskripsi = false;
      this.$nextTick(() => {
        this.deskripsiItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveDeskripsi(deskripsiItem) {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("deskripsi_id", deskripsiItem.deskripsi_id);
        formData.set("deskripsi_en", deskripsiItem.deskripsi_en);
        this.axios
          .post(
            "/api/auth/updateDeskripsi/" + deskripsiItem.uuid,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "success",
              text: this.$t("notif.ubah"),
            });
            this.closeDeskripsi();
            this.getMataKuliah();
          })
          .catch((error) => {
            this.$vloading.hide();
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    chekCPL(item) {
      // console.log(item);
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("uid_mata_kuliah", this.$route.params.id);
      formData.set("cpl_id", item.id);
      this.axios
        .post("/api/auth/saveCplMk", formData, config)
        .then(() => {
          this.getCplByProdiMk();
          this.getCplMkByKodeMk();
          this.getCpmkByKodeMk();
          this.getCplByKodeMkWu();
          this.snackbar = true;
          this.loading = false;
        })
        .catch((error) => {
          this.getCplByProdiMk();
          this.getCplMkByKodeMk();
          this.getCpmkByKodeMk();
          this.getCplByKodeMkWu();
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    unchekCPL(item) {
      // console.log(item);
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyCplMk/" + item.cpl_mk.id, config)
        .then(() => {
          this.getCplByProdiMk();
          this.getCplMkByKodeMk();
          this.getCpmkByKodeMk();
          this.getCplByKodeMkWu();
          this.snackbar = true;
          this.loading = false;
        })
        .catch((error) => {
          this.getCplByProdiMk();
          this.getCplMkByKodeMk();
          this.getCpmkByKodeMk();
          this.getCplByKodeMkWu();
          this.loading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    chekCPMKCPL(item) {
      // console.log(item);
      const sel = item.split("-").map(Number);
      const cpmkID = sel[0];
      const cplID = sel[1];
      // console.log(cpmkID);
      // console.log(cplID);
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("cpmk_id", cpmkID);
      formData.set("cpl_id", cplID);
      this.axios
        .post("/api/auth/saveCpmkCpl", formData, config)
        .then(() => {
          this.getCpmkCplByKodeMk();
          this.snackbar = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    unchekCPMKCPL(item) {
      // console.log(item);
      const sel = item.split("-").map(Number);
      const cpmkID = sel[0];
      const cplID = sel[1];
      // console.log(cpmkID);
      // console.log(cplID);
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyCpmkCpl/" + cpmkID + "/" + cplID, config)
        .then(() => {
          this.getCpmkCplByKodeMk();
          this.snackbar = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    tambahCPMK() {
      if (this.$refs.formTambahCPMK.validate()) {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("uid_mata_kuliah", this.$route.params.id);
        formData.set("kode_cpmk", this.tambah_kode_cpmk);
        formData.set("cpmk_id", this.tambah_cpmk_id);
        formData.set("cpmk_en", this.tambah_cpmk_en);
        formData.set("bobot_cpmk", this.tambah_bobot_cpmk);
        this.axios
          .post("/api/auth/saveCpmk", formData, config)
          .then(() => {
            this.getCpmkByKodeMk();
            this.snackbar = true;
            this.loading = false;
            this.tambah_kode_cpmk = "";
            this.tambah_cpmk_id = "";
            this.tambah_cpmk_en = "";
            this.tambah_bobot_cpmk = "";
            this.validTambahCPMK = true;
            this.isTambahCPMK = !this.isTambahCPMK;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    editCPMK(item) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("uid_mata_kuliah", this.$route.params.id);
      formData.set("kode_cpmk", item.kode_cpmk);
      formData.set("cpmk_id", item.cpmk_id);
      formData.set("cpmk_en", item.cpmk_en);
      formData.set("bobot_cpmk", item.bobot_cpmk);
      this.axios
        .post("/api/auth/saveCpmk/" + item.id, formData, config)
        .then(() => {
          this.getCpmkByKodeMk();
          this.snackbar = true;
          this.loading = false;
          this.isEditing = null;
        })
        .catch((error) => {
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    hapusCPMK(item) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyCpmk/" + item, config)
        .then(() => {
          this.getCpmkByKodeMk();
          this.snackbar = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    tambahRPS() {
      if (this.$refs.formTambahRPS.validate()) {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("uid_mata_kuliah", this.$route.params.id);
        formData.set(
          "metode_pembelajaran_id",
          this.tambah_metode_pembelajaran_id
        );
        formData.set("minggu", this.tambah_minggu);
        formData.set("materi_id", this.tambah_materi_id);
        formData.set("materi_en", this.tambah_materi_en);
        if (this.tambah_metode_pembelajaran_id == 3) {
          formData.set("keterangan", this.tambah_keterangan);
        } else {
          formData.set("keterangan", "");
        }
        this.axios
          .post("/api/auth/saveRencanaPembelajaran", formData, config)
          .then(() => {
            this.getRencanaPembelajaranByKodeMk();
            this.snackbar = true;
            this.loading = false;
            this.tambah_metode_pembelajaran_id = "";
            this.tambah_keterangan = "";
            this.tambah_minggu = "";
            this.tambah_materi_id = "";
            this.tambah_materi_en = "";
            this.validTambahRPS = true;
            this.isTambahRPS = !this.isTambahRPS;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    editRPS(item) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("uid_mata_kuliah", this.$route.params.id);
      formData.set("metode_pembelajaran_id", item.metode_pembelajaran_id);
      formData.set("minggu", item.minggu);
      formData.set("materi_id", item.materi_id);
      formData.set("materi_en", item.materi_en);
      if (item.metode_pembelajaran_id == 3) {
        formData.set("keterangan", item.keterangan);
      } else {
        formData.set("keterangan", "");
      }
      this.axios
        .post("/api/auth/saveRencanaPembelajaran/" + item.id, formData, config)
        .then(() => {
          this.getRencanaPembelajaranByKodeMk();
          this.snackbar = true;
          this.loading = false;
          this.isEditingRPS = null;
        })
        .catch((error) => {
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    hapusRPS(item) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyRencanaPembelajaran/" + item, config)
        .then(() => {
          this.getRencanaPembelajaranByKodeMk();
          this.snackbar = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    insertRAECPMK(item) {
      // console.log(item);
      const sel = item.split("-").map(Number);
      const raeID = sel[0];
      const cpmkID = sel[1];
      const bobotRAECPMK = sel[2];
      // console.log(bobotRAECPMK);

      if (isNaN(bobotRAECPMK)) {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("rae_id", raeID);
        formData.set("cpmk_id", cpmkID);
        formData.set("bobot_rae_cpmk", "0");
        this.axios
          .post("/api/auth/saveRaeCpmk", formData, config)
          .then(() => {
            this.getRaeCpmkByKodeMk();
            // this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      } else {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("rae_id", raeID);
        formData.set("cpmk_id", cpmkID);
        formData.set("bobot_rae_cpmk", bobotRAECPMK);
        this.axios
          .post("/api/auth/saveRaeCpmk", formData, config)
          .then(() => {
            this.getRaeCpmkByKodeMk();
            // this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    updateRAECPMK(item) {
      // console.log(item);
      const sel = item.split("-").map(Number);
      const raeID = sel[0];
      const cpmkID = sel[1];
      const bobotRAECPMK = sel[2];
      const idRAECPMK = sel[3];
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("rae_id", raeID);
      formData.set("cpmk_id", cpmkID);
      formData.set("bobot_rae_cpmk", bobotRAECPMK);
      this.axios
        .post("/api/auth/saveRaeCpmk/" + idRAECPMK, formData, config)
        .then(() => {
          this.getRaeCpmkByKodeMk();
          // this.snackbar = true;
          this.loading = false;
          this.isEditingRAECPMK = null;
        })
        .catch((error) => {
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    tambahRAE() {
      if (this.$refs.formTambahRAE.validate()) {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("uid_mata_kuliah", this.$route.params.id);
        formData.set("jenis_evaluasi_id", this.tambah_jenis_evaluasi_id);
        formData.set("rae_id", this.tambah_rae_id);
        formData.set("rae_en", this.tambah_rae_en);
        // if (this.tambah_jenis_evaluasi_id == 3) {
        //   formData.set("keterangan", this.tambah_keterangan_rae);
        // } else {
        //   formData.set("keterangan", "");
        // }
        this.axios
          .post("/api/auth/saveRae", formData, config)
          .then(() => {
            this.getRaeByKodeMk();
            this.snackbar = true;
            this.loading = false;
            this.tambah_keterangan_rae = "";
            this.tambah_jenis_evaluasi_id = "";
            this.tambah_rae_id = "";
            this.tambah_rae_en = "";
            this.validTambahRAE = true;
            this.isTambahRAE = !this.isTambahRAE;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    editRAE(item) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("uid_mata_kuliah", this.$route.params.id);
      formData.set("jenis_evaluasi_id", item.jenis_evaluasi_id);
      formData.set("rae_id", item.rae_id);
      formData.set("rae_en", item.rae_en);
      // if (item.jenis_evaluasi_id == 3) {
      //   formData.set("keterangan", item.keterangan);
      // } else {
      //   formData.set("keterangan", "");
      // }
      this.axios
        .post("/api/auth/saveRae/" + item.id, formData, config)
        .then(() => {
          this.getRaeByKodeMk();
          this.snackbar = true;
          this.loading = false;
          this.isEditingRAE = null;
        })
        .catch((error) => {
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    hapusRAE(item) {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyRae/" + item, config)
        .then(() => {
          this.getRaeByKodeMk();
          this.snackbar = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    simpanPermanen() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("status", "1");
      this.axios
        .post(
          "/api/auth/updateMataKuliah/" + this.$route.params.id,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: "Mata Kuliah berhasil dipermanenkan!",
          });
          this.dialogPermanen = false;
          this.getMataKuliah();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.dialogPermanen = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    simpanPermanenBatal() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("status", "0");
      this.axios
        .post(
          "/api/auth/updateMataKuliah/" + this.$route.params.id,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: "Status permanen Mata Kuliah berhasil dibatalkan!",
          });
          this.dialogPermanenBatal = false;
          this.getMataKuliah();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.dialogPermanenBatal = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.table >>> th {
  font-size: 9pt !important;
}
.table >>> tr > td {
  font-size: 10pt !important;
}

.table >>> .v-data-footer__select,
.table >>> .v-select__selection,
.table >>> .v-data-footer__pagination {
  font-size: 9pt;
}
</style>