<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">DASHBOARD</div>
        </v-col>
        <br />
        <v-col cols="12" v-if="!guest">
          <v-card tile elevation="1">
            <v-card-text class="text-subtitle-1 font-weight-light black--text"
              >{{ $t("selamat_datang") }},<br />
              <h2>{{ user.data.nama }}</h2>
              {{ $t("dashboard1") }}
              <b>
                <span v-if="user.data.aktif.operator == true"> OPERATOR </span>
                <span v-if="user.data.aktif.role == 1"> ADMINISTRATOR </span>
                <span v-else-if="user.data.aktif.role == 2"> TATA USAHA </span>
                <span v-else-if="user.data.aktif.role == 3"> KAJUR </span>
                <span v-else-if="user.data.aktif.role == 4"> KAPRODI </span>
                <span v-else-if="user.data.aktif.role == 5"> DOSEN </span>
                <span v-else-if="user.data.aktif.role == 6">
                  KEPALA PUSAT MKWU
                </span>
              </b>
              <span v-if="user.data.aktif.role != 1 && user.data.aktif.role != 6">
                {{ $t("dashboard2") }}
                <b
                  ><span v-if="user.data.aktif.prodi.jenjang == 'A'"> S3 </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'B'">
                    S2
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'C'">
                    S1
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'D'">
                    D4
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'E'">
                    D3
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'F'">
                    D2
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'G'">
                    D1
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'H'">
                    Sp-1
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'I'">
                    Sp-2
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'J'">
                    PROFESI
                  </span>
                  <span v-else-if="user.data.aktif.prodi.jenjang == 'X'">
                    NON AKADEMIK
                  </span>
                  {{ user.data.aktif.prodi.nama_prodi_id }}</b
                ></span
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-else>
          <v-card tile elevation="1" align="center">
            <v-card-text class="text-body-1 font-weight-reguler black--text"
              >{{ $t("selamat_datang_di") }}<br /><b
                >{{ appNameNormal }}<br />{{ appNameUNG }}</b
              ></v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data: () => ({
    model: null,
  }),
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
    ...mapGetters({
      countCart: "cart/count",
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },
  created() {},

  methods: {},
};
</script>
