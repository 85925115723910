import { render, staticRenderFns } from "./MonitoringEvaluasiDetail.vue?vue&type=template&id=8dbcf60e&scoped=true&"
import script from "./MonitoringEvaluasiDetail.vue?vue&type=script&lang=js&"
export * from "./MonitoringEvaluasiDetail.vue?vue&type=script&lang=js&"
import style0 from "./MonitoringEvaluasiDetail.vue?vue&type=style&index=0&id=8dbcf60e&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dbcf60e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardActions,VCardText,VChip,VCol,VContainer,VDivider,VRow,VSimpleTable,VSpacer,VTooltip})
