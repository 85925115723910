<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("kurikulum.kurikulum").toUpperCase() }} &raquo;
            {{ $t("kurikulum.daftar_kurikulum").toUpperCase() }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="datas"
        class="elevation-1"
        item-key="name"
        :search="search"
      >
        <template v-slot:item.nomor="{ item }">
          {{ datas.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.jenjang="{ item }">
          <span v-if="item.prodi.jenjang == 'A'"> S3 </span>
          <span v-else-if="item.prodi.jenjang == 'B'"> S2 </span>
          <span v-else-if="item.prodi.jenjang == 'C'"> S1 </span>
          <span v-else-if="item.prodi.jenjang == 'D'"> D4 </span>
          <span v-else-if="item.prodi.jenjang == 'E'"> D3 </span>
          <span v-else-if="item.prodi.jenjang == 'F'"> D2 </span>
          <span v-else-if="item.prodi.jenjang == 'G'"> D1 </span>
          <span v-else-if="item.prodi.jenjang == 'H'"> Sp-1 </span>
          <span v-else-if="item.prodi.jenjang == 'I'"> Sp-2 </span>
          <span v-else-if="item.prodi.jenjang == 'J'"> Profesi </span>
          <span v-else-if="item.prodi.jenjang == 'X'"> Non Akademik </span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                $t("kurikulum.daftar_kurikulum")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <!-- <template
                v-slot:activator="{ on, attrs }"
                v-if="user.data.aktif.role != '5'"
              >
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.kode_prodi"
                            :items="prodiItems"
                            :rules="rules"
                            item-value="kode_prodi"
                            item-text="nama_prodi_id"
                            :label="$t('master.prodi')"
                            clearable
                            single-line
                            dense
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.nama_prodi_id }} - {{ item.jenjang }}
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item.nama_prodi_id }} - {{ item.jenjang }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nama_kurikulum_id"
                            :rules="rules"
                            :label="$t('kurikulum.nama_kurikulum_id')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nama_kurikulum_en"
                            :rules="rules"
                            :label="$t('kurikulum.nama_kurikulum_en')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.tahun"
                            :rules="rules"
                            type="number"
                            :label="$t('kurikulum.tahun_kurikulum')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("tabel.batal") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                    >
                      {{ $t("tabel.simpan") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("tabel.dialog_hapus")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("tabel.batal")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("tabel.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            :label="$t('tabel.cari')"
            class="mx-4 pt-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <span v-if="user.data.aktif.role != '5'">
            <v-icon small color="warning" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <!-- <v-icon small color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
          </span>
        </template>
        <template v-slot:no-data> {{ $t("tabel.tidak_ada_data") }} </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Kurikulum",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    datas: [],
    prodiItems: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        { text: "No", value: "nomor" },
        {
          text: this.$t("kurikulum.nama_kurikulum_id"),
          value: "nama_kurikulum_id",
        },
        {
          text: this.$t("kurikulum.nama_kurikulum_en"),
          value: "nama_kurikulum_en",
        },
        // {
        //   text: this.$t("kurikulum.tahun_kurikulum"),
        //   value: "tahun",
        // },
        { text: this.$t("master.prodi"), value: "prodi.nama_prodi_id" },
        { text: this.$t("master.prodi_jenjang"), value: "prodi.jenjang" },
        { text: this.$t("tabel.aksi"), value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      if (this.user.data.aktif.role == "1") {
        this.getKurikulum();
      } else {
        this.getKurikulumByKodeProdi();
      }
      this.getProdi();
    },

    getKurikulum() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/kurikulumList", config)
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getKurikulumByKodeProdi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getKurikulumByKodeProdi/" +
            this.user.data.aktif.kode_prodi,
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getProdi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/prodiList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            this.prodiItems = response.data.data;
          } else {
            var Data = response.data.data;
            var newData = Data.filter(
              (e) => e.kode_prodi == this.user.data.aktif.kode_prodi
            );
            this.prodiItems = newData;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyKurikulum/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.hapus"),
          });
          this.close();
          if (this.user.data.aktif.role == "1") {
            this.getKurikulum();
          } else {
            this.getKurikulumByKodeProdi();
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("kode_prodi", this.editedItem.kode_prodi);
          formData.set("nama_kurikulum_id", this.editedItem.nama_kurikulum_id);
          formData.set("nama_kurikulum_en", this.editedItem.nama_kurikulum_en);
          formData.set("tahun", this.editedItem.tahun);
          this.axios
            .post(
              "/api/auth/saveKurikulum/" + this.editedItem.id,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.ubah"),
              });
              this.close();
              if (this.user.data.aktif.role == "1") {
                this.getKurikulum();
              } else {
                this.getKurikulumByKodeProdi();
              }
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        } else {
          // CREATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("kode_prodi", this.editedItem.kode_prodi);
          formData.set("nama_kurikulum_id", this.editedItem.nama_kurikulum_id);
          formData.set("nama_kurikulum_en", this.editedItem.nama_kurikulum_en);
          formData.set("tahun", this.editedItem.tahun);
          this.axios
            .post("/api/auth/saveKurikulum", formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.tambah"),
              });
              this.close();
              if (this.user.data.aktif.role == "1") {
                this.getKurikulum();
              } else {
                this.getKurikulumByKodeProdi();
              }
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>