var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"grey--text text-body-1"},[_vm._v(" MASTER » "+_vm._s(_vm.$t("hak_akses").toUpperCase())+" ")])])],1)],1),_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datas,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"item.nomor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datas.indexOf(item) + 1)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.role == 1)?_c('span',[_vm._v(" ADMINISTRATOR ")]):(item.role == 2)?_c('span',[_vm._v(" TATA USAHA ")]):(item.role == 3)?_c('span',[_vm._v(" KAJUR ")]):(item.role == 4)?_c('span',[_vm._v(" KAPRODI ")]):(item.role == 5)?_c('span',[_vm._v(" DOSEN ")]):(item.role == 6)?_c('span',[_vm._v(" KEPALA PUSAT MKWU ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("hak_akses")))])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1),(_vm.user.data.aktif.role == 4)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"warning","dark":""},on:{"click":_vm.syncHakAkses}},[_c('v-icon',[_vm._v("mdi-cached")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.dosenItems,"rules":_vm.rules,"item-value":"user_id","item-text":"nama","label":"Dosen","clearable":"","single-line":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nama)+" - "+_vm._s(item.nidn)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nama)+" - "+_vm._s(item.nidn)+" ")]}}]),model:{value:(_vm.editedItem.user_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "user_id", $$v)},expression:"editedItem.user_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.fakultasItems,"rules":_vm.rules,"item-value":"kode_fakultas","item-text":"nama_fakultas_id","label":"Fakultas","clearable":"","single-line":"","dense":""},on:{"change":_vm.fakultasChange},model:{value:(_vm.editedItem.kode_fakultas),callback:function ($$v) {_vm.$set(_vm.editedItem, "kode_fakultas", $$v)},expression:"editedItem.kode_fakultas"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.jurusanItems,"rules":_vm.rules,"item-value":"kode_jurusan","item-text":"nama_jurusan_id","label":"Jurusan","clearable":"","single-line":"","dense":""},on:{"change":_vm.jurusanChange},model:{value:(_vm.editedItem.kode_jurusan),callback:function ($$v) {_vm.$set(_vm.editedItem, "kode_jurusan", $$v)},expression:"editedItem.kode_jurusan"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.prodiItems,"rules":_vm.rules,"item-value":"kode_prodi","item-text":"nama_prodi_id","label":"Program Studi","clearable":"","single-line":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jenjang)+" - "+_vm._s(item.nama_prodi_id)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jenjang)+" - "+_vm._s(item.nama_prodi_id)+" ")]}}]),model:{value:(_vm.editedItem.kode_prodi),callback:function ($$v) {_vm.$set(_vm.editedItem, "kode_prodi", $$v)},expression:"editedItem.kode_prodi"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.roleItems,"rules":_vm.rules,"item-value":"id","item-text":"role","label":"Pilih Role","clearable":"","single-line":"","dense":""},model:{value:(_vm.editedItem.role),callback:function ($$v) {_vm.$set(_vm.editedItem, "role", $$v)},expression:"editedItem.role"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("tabel.batal"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":!_vm.valid,"text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("tabel.simpan"))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("tabel.dialog_hapus")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("tabel.batal")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("tabel.ok")))]),_c('v-spacer')],1)],1)],1)],1),_c('v-text-field',{staticClass:"mx-4 pt-4",attrs:{"label":_vm.$t('tabel.cari')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("tabel.tidak_ada_data"))+" ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }