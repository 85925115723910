<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("kurikulum.kurikulum").toUpperCase() }} &raquo;
            {{ $t("cpl.cpl").toUpperCase() }}
          </div>
        </v-col>
        <br />
        <v-col cols="10 offset-1">
          <v-form ref="form" v-model="validKurikulum" lazy-validation>
            <v-card tile elevation="1" class="pa-2">
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="3" align="center">
                    <label> {{ $t("master.prodi") }}: </label>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    <v-autocomplete
                      v-model="prodiSelect"
                      :items="prodiItems"
                      :rules="rules"
                      item-value="kode_prodi"
                      item-text="nama_prodi_id"
                      :label="$t('master.prodi')"
                      clearable
                      single-line
                      dense
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.nama_prodi_id }} - {{ item.jenjang }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.nama_prodi_id }} - {{ item.jenjang }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" align="center">
                    <v-btn
                      color="primary"
                      class="mr-4"
                      @click="getCPL(prodiSelect)"
                      :disabled="!validKurikulum"
                    >
                      {{ $t("tombol.tampilkan") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="datas"
        class="elevation-1"
        item-key="name"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.nomor="{ item }">
          {{ datas.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.kode_cpl="{ item }">
          {{ $t("cpl.cpl_kode") }}-{{ item.kode_cpl }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("cpl.cpl") }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template
                v-slot:activator="{ on, attrs }"
                v-if="user.data.aktif.role != '5'"
              >
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.kode_cpl"
                            :rules="rules"
                            :prefix="$t('cpl.cpl_kode') + '-'"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-model="editedItem.cpl_id"
                            :rules="rules"
                            :label="$t('cpl.cpl_id')"
                            rows="5"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-model="editedItem.cpl_en"
                            :rules="rules"
                            :label="$t('cpl.cpl_en')"
                            rows="5"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("tabel.batal") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                    >
                      {{ $t("tabel.simpan") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("tabel.dialog_hapus")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("tabel.batal")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("tabel.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            :label="$t('tabel.cari')"
            class="mx-4 pt-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <span v-if="user.data.aktif.role != '5'">
            <v-icon small color="warning" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </span>
        </template>
        <template v-slot:no-data> {{ $t("tabel.tidak_ada_data") }} </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CPL",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    valid: true,
    validKurikulum: true,
    search: "",
    datas: [],
    prodiSelect: parseInt(localStorage.getItem("prodiSelect")),
    prodiItems: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        { text: "No", value: "nomor" },
        { text: "Kode", value: "kode_cpl", width: "10%" },
        { text: this.$t("cpl.cpl_id"), value: "cpl_id" },
        { text: this.$t("cpl.cpl_en"), value: "cpl_en" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getProdi();
      this.getCPL(parseInt(localStorage.getItem("prodiSelect")));
    },

    getCPL(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getCplByProdi/" + id, config)
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
          localStorage.prodiSelect = this.prodiSelect;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getProdi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/prodiList", config)
        .then((response) => {
          // this.$vloading.hide();
          // this.prodiItems = response.data.data;
          // this.prodiSelect = parseInt(localStorage.getItem("prodiSelect"));

          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            this.prodiItems = response.data.data;
            this.prodiSelect = parseInt(localStorage.getItem("prodiSelect"));
          } else {
            var Data = response.data.data;
            var newData = Data.filter(
              (e) => e.kode_prodi == this.user.data.aktif.kode_prodi
            );
            this.prodiItems = newData;
            this.prodiSelect = parseInt(localStorage.getItem("prodiSelect"));
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyCpl/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.hapus"),
          });
          this.close();
          this.getCPL(parseInt(localStorage.getItem("prodiSelect")));
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("kode_prodi", this.prodiSelect);
          formData.set("kode_cpl", this.editedItem.kode_cpl);
          formData.set("cpl_id", this.editedItem.cpl_id);
          formData.set("cpl_en", this.editedItem.cpl_en);
          formData.set("kategori", "1");
          this.axios
            .post("/api/auth/saveCpl/" + this.editedItem.id, formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.ubah"),
              });
              this.close();
              this.getCPL(parseInt(localStorage.getItem("prodiSelect")));
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        } else {
          // CREATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("kode_prodi", this.prodiSelect);
          formData.set("kode_cpl", this.editedItem.kode_cpl);
          formData.set("cpl_id", this.editedItem.cpl_id);
          formData.set("cpl_en", this.editedItem.cpl_en);
          formData.set("kategori", "1");
          this.axios
            .post("/api/auth/saveCpl", formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.tambah"),
              });
              this.close();
              this.getCPL(parseInt(localStorage.getItem("prodiSelect")));
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>