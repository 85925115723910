<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            MASTER &raquo;
            {{ $t("master.jenis_evaluasi").toUpperCase() }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="datas"
        class="elevation-1"
        item-key="name"
        :search="search"
      >
        <template v-slot:item.nomor="{ item }">
          {{ datas.indexOf(item) + 1 }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                $t("master.jenis_evaluasi")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nama_evaluasi_id"
                            :rules="rules"
                            :label="$t('master.jenis_evaluasi_nama_id')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nama_evaluasi_en"
                            :rules="rules"
                            :label="$t('master.jenis_evaluasi_nama_en')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="editedItem.kelompok"
                            :items="kelompokItems"
                            :rules="rules"
                            item-value="id"
                            item-text="kelompok"
                            :label="$t('master.jenis_evaluasi_kelompok')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-model="editedItem.deskripsi"
                            :rules="rules"
                            :label="$t('master.jenis_evaluasi_deskripsi')"
                            rows="5"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("tabel.batal") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                    >
                      {{ $t("tabel.simpan") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("tabel.dialog_hapus")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("tabel.batal")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("tabel.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            :label="$t('tabel.cari')"
            class="mx-4 pt-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small color="warning" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small color="error" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data> {{ $t("tabel.tidak_ada_data") }} </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "JenisEvaluasi",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    datas: [],
    kelompokItems: [
      { id: "CBL", kelompok: "CBL" },
      { id: "PJBL", kelompok: "PJBL" },
      { id: "Kognitif", kelompok: "Kognitif" },
      { id: "Keterampilan", kelompok: "Keterampilan" },
      { id: "Sikap", kelompok: "Sikap" },
      { id: "Non Kelompok", kelompok: "Non Kelompok" },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        { text: "No", value: "nomor" },
        {
          text: this.$t("master.jenis_evaluasi_nama_id"),
          value: "nama_evaluasi_id",
        },
        {
          text: this.$t("master.jenis_evaluasi_nama_en"),
          value: "nama_evaluasi_en",
        },
        {
          text: this.$t("master.jenis_evaluasi_kelompok"),
          value: "kelompok",
        },
        {
          text: this.$t("master.jenis_evaluasi_deskripsi"),
          value: "deskripsi",
        },
        { text: this.$t("tabel.aksi"), value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getJenisEvaluasi();
    },

    getJenisEvaluasi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/jenisEvaluasiList", config)
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
          console.log(this.datas);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyJenisEvaluasi/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.hapus"),
          });
          this.close();
          this.getJenisEvaluasi();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("nama_evaluasi_id", this.editedItem.nama_evaluasi_id);
          formData.set("nama_evaluasi_en", this.editedItem.nama_evaluasi_en);
          formData.set("kelompok", this.editedItem.kelompok);
          formData.set("deskripsi", this.editedItem.deskripsi);
          this.axios
            .post(
              "/api/auth/saveJenisEvaluasi/" + this.editedItem.id,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.ubah"),
              });
              this.close();
              this.getJenisEvaluasi();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        } else {
          // CREATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("nama_evaluasi_id", this.editedItem.nama_evaluasi_id);
          formData.set("nama_evaluasi_en", this.editedItem.nama_evaluasi_en);
          formData.set("kelompok", this.editedItem.kelompok);
          formData.set("deskripsi", this.editedItem.deskripsi);
          this.axios
            .post("/api/auth/saveJenisEvaluasi", formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.tambah"),
              });
              this.close();
              this.getJenisEvaluasi();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>