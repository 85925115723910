<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            MASTER &raquo;
            {{ $t("hak_akses").toUpperCase() }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="datas"
        class="elevation-1"
        item-key="name"
        :search="search"
      >
        <template v-slot:item.nomor="{ item }">
          {{ datas.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.role="{ item }">
          <span v-if="item.role == 1"> ADMINISTRATOR </span>
          <span v-else-if="item.role == 2"> TATA USAHA </span>
          <span v-else-if="item.role == 3"> KAJUR </span>
          <span v-else-if="item.role == 4"> KAPRODI </span>
          <span v-else-if="item.role == 5"> DOSEN </span>
          <span v-else-if="item.role == 6"> KEPALA PUSAT MKWU </span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("hak_akses") }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn color="warning" dark class="mb-2" @click="syncHakAkses" v-if="user.data.aktif.role == 4">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.user_id"
                            :items="dosenItems"
                            :rules="rules"
                            item-value="user_id"
                            item-text="nama"
                            label="Dosen"
                            clearable
                            single-line
                            dense
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.nama }} - {{ item.nidn }}
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item.nama }} - {{ item.nidn }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.kode_fakultas"
                            :items="fakultasItems"
                            :rules="rules"
                            item-value="kode_fakultas"
                            item-text="nama_fakultas_id"
                            label="Fakultas"
                            clearable
                            single-line
                            dense
                            v-on:change="fakultasChange"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.kode_jurusan"
                            :items="jurusanItems"
                            :rules="rules"
                            item-value="kode_jurusan"
                            item-text="nama_jurusan_id"
                            label="Jurusan"
                            clearable
                            single-line
                            dense
                            v-on:change="jurusanChange"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.kode_prodi"
                            :items="prodiItems"
                            :rules="rules"
                            item-value="kode_prodi"
                            item-text="nama_prodi_id"
                            label="Program Studi"
                            clearable
                            single-line
                            dense
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.jenjang }} - {{ item.nama_prodi_id }}
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item.jenjang }} - {{ item.nama_prodi_id }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.role"
                            :items="roleItems"
                            :rules="rules"
                            item-value="id"
                            item-text="role"
                            label="Pilih Role"
                            clearable
                            single-line
                            dense
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("tabel.batal") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                    >
                      {{ $t("tabel.simpan") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("tabel.dialog_hapus")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("tabel.batal")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("tabel.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            :label="$t('tabel.cari')"
            class="mx-4 pt-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small color="warning" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small color="error" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data> {{ $t("tabel.tidak_ada_data") }} </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HakAkses",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    datas: [],
    dosenItems: [],
    fakultasItems: [],
    jurusanItems: [],
    prodiItems: [],
    roleItems: [
      { id: "2", role: "TATA USAHA" },
      { id: "3", role: "KAJUR" },
      { id: "4", role: "KAPRODI" },
      { id: "5", role: "DOSEN" },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    headers: [],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getHakAkses();
      this.getDosen();
      this.getFakultas();
    },

    getHakAkses() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/hakAksesList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            this.datas = response.data.data;

            this.headers = [
              { text: "No", value: "nomor" },
              { text: "NIDN", value: "dosen.nidn" },
              { text: "Nama", value: "dosen.nama" },
              { text: "Role", value: "role" },
              { text: "Jurusan", value: "jurusan.nama_jurusan_id" },
              { text: "Prodi", value: "prodi.nama_prodi_id" },
              {
                text: this.$t("tabel.aksi"),
                value: "actions",
                sortable: false,
              },
            ];
          } else {
            var Data = response.data.data;
            var newData = Data.filter(
              (e) => e.kode_prodi == this.user.data.aktif.kode_prodi
            );
            this.datas = newData;

            this.headers = [
              { text: "No", value: "nomor" },
              { text: "NIDN", value: "dosen.nidn" },
              { text: "Nama", value: "dosen.nama" },
              { text: "Role", value: "role" },
              {
                text: this.$t("tabel.aksi"),
                value: "actions",
                sortable: false,
              },
            ];
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getDosen() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/dosenList", config)
        .then((response) => {
          this.$vloading.hide();
          this.dosenItems = response.data.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getFakultas() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/fakultasList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            this.fakultasItems = response.data.data;
          } else {
            var Data = response.data.data;
            var newData = Data.filter(
              (e) => e.kode_fakultas == this.user.data.aktif.kode_fakultas
            );
            this.fakultasItems = newData;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    fakultasChange: function (e) {
      this.getJurusan(e);
    },

    getJurusan(item) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/jurusanList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            var Data = response.data.data;
            var newData = Data.filter((e) => e.kode_fakultas == item);
            this.jurusanItems = newData;
          } else {
            var Data2 = response.data.data;
            var newData2 = Data2.filter(
              (e) => e.kode_jurusan == this.user.data.aktif.kode_jurusan
            );
            this.jurusanItems = newData2;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    jurusanChange: function (e) {
      this.getProdi(e);
    },

    getProdi(item) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/prodiList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            var Data = response.data.data;
            var newData = Data.filter((e) => e.kode_jurusan == item);
            this.prodiItems = newData;
          } else {
            var Data2 = response.data.data;
            var newData2 = Data2.filter(
              (e) => e.kode_prodi == this.user.data.aktif.kode_prodi
            );
            this.prodiItems = newData2;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.getJurusan(item.kode_fakultas);
      this.getProdi(item.kode_jurusan);
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyHakAkses/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.hapus"),
          });
          this.close();
          this.getHakAkses();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("user_id", this.editedItem.user_id);
          formData.set("kode_fakultas", this.editedItem.kode_fakultas);
          formData.set("kode_jurusan", this.editedItem.kode_jurusan);
          formData.set("kode_prodi", this.editedItem.kode_prodi);
          formData.set("role", this.editedItem.role);
          this.axios
            .post(
              "/api/auth/saveHakAkses/" + this.editedItem.id,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.ubah"),
              });
              this.close();
              this.getHakAkses();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        } else {
          // CREATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("user_id", this.editedItem.user_id);
          formData.set("kode_fakultas", this.editedItem.kode_fakultas);
          formData.set("kode_jurusan", this.editedItem.kode_jurusan);
          formData.set("kode_prodi", this.editedItem.kode_prodi);
          formData.set("role", this.editedItem.role);
          this.axios
            .post("/api/auth/saveHakAkses", formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.tambah"),
              });
              this.close();
              this.getHakAkses();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    syncHakAkses() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("kode_fakultas", this.user.data.aktif.kode_fakultas);
      formData.set("kode_jurusan", this.user.data.aktif.kode_jurusan);
      formData.set("kode_prodi", this.user.data.aktif.kode_prodi);
      this.axios
        .post("/api/auth/syncHakAkses", formData, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.tambah"),
          });
          this.close();
          this.getHakAkses();
        })
        .catch((error) => {
          this.$vloading.hide();
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>