<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("monitoring.monitoring").toUpperCase() }} &raquo;
            {{ $t("monitoring.monitoring_evaluasi").toUpperCase() }} &raquo;
            DETAIL
          </div>
        </v-col>
        <br />
        <v-col cols="12" v-for="dt in details" :key="dt.id">
          <v-card tile elevation="1" class="pa-4 text-body-2">
            <table border="0" style="width: 100%; padding-top: 4pt">
              <tr>
                <td class="text-left">Mata Kuliah</td>
                <td class="text-right">
                  <b
                    >{{ dt.mata_kuliah.kode_mata_kuliah }} -
                    {{ dt.mata_kuliah.nama_mata_kuliah_id }}</b
                  >
                </td>
              </tr>
              <tr>
                <td class="text-left">Semester</td>
                <td class="text-right">
                  <b>{{ dt.semester.nama }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left">Kelas</td>
                <td class="text-right">
                  <b>{{ dt.kelas }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left">Jumlah Mahasiswa</td>
                <td class="text-right">
                  <b>{{ dt.jumlah_mahasiswa }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left" valign="top">
                  Pengajar<br /><b>
                    <span
                      v-for="pengajar in dt.mata_kuliah.pengampu"
                      :key="pengajar.id"
                    >
                      {{ dt.mata_kuliah.pengampu.indexOf(pengajar) + 1 }}.
                      {{ pengajar.dosen.nama }}
                      <v-chip
                        v-if="koordinator == pengajar.dosen.nidn"
                        color="warning"
                        x-small
                        label
                        class="mb-1"
                      >
                        Koordinator </v-chip
                      ><br />
                    </span>
                  </b>
                </td>
                <td class="text-right"></td>
              </tr>
            </table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Ketercapaian IKU 7 -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card tile :loading="loading">
            <v-row>
              <v-col cols="12" lg="7" md="7">
                <v-card-text class="black--text">
                  <v-card-actions>
                    <h3>IKU 7</h3>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                  <v-divider></v-divider>
                  <br />
                  <v-simple-table class="table" dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Kelompok</th>
                          <th class="text-center">Bobot (%)</th>
                          <th class="text-center">Keterangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="mr in monitoringRae" :key="mr.id">
                          <td class="text-center">
                            {{ mr.kelompok }}
                          </td>
                          <td class="text-center">{{ mr.bobot }} %</td>
                          <td class="text-center">
                            <span
                              v-if="
                                mr.kelompok == 'CBL' || mr.kelompok == 'PJBL'
                              "
                            >
                              IKU 7
                            </span>
                            <span v-else> Non IKU </span>
                          </td>
                        </tr>
                      </tbody>
                      <!-- <tfoot>
                    <tr bgcolor="lightgrey">
                      <td class="text-center">
                        <b>Total</b>
                      </td>
                      <td class="text-center">
                        <b>Total</b>
                      </td>
                      <td class="text-center">
                        <b>Total</b>
                      </td>
                    </tr>
                  </tfoot> -->
                    </template>
                  </v-simple-table>
                  <v-divider></v-divider>
                </v-card-text>
              </v-col>
              <v-col cols="12" lg="5" md="5">
                <v-card-text class="black--text">
                  <div id="chart">
                    <apexchart
                      type="pie"
                      width="350"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Ketercapaian CPMK -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card tile :loading="loading">
            <v-card-text class="black--text">
              <v-card-actions>
                <h3>Ketercapaian CPMK Per Mahasiswa</h3>
                <v-spacer></v-spacer>
              </v-card-actions>
              <v-divider></v-divider>
              <br />
              <v-simple-table class="table" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">#</th>
                      <th class="text-center">NIM</th>
                      <th class="text-center">Nama Mahasiswa</th>
                      <th class="text-center" v-for="cp in cpmk" :key="cp.id">
                        <v-tooltip bottom color="info" max-width="20%">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="blue--text"
                              >CPMK-{{ cp.kode_cpmk }} ({{
                                cp.bobot_cpmk
                              }}%)</span
                            >
                          </template>
                          <span><b>Deskripsi:</b><br />{{ cp.cpmk_id }}</span>
                        </v-tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="mhs in datas" :key="mhs.id">
                      <td class="text-center">{{ datas.indexOf(mhs) + 1 }}</td>
                      <td class="text-center">{{ mhs.mahasiswa.nim }}</td>
                      <td class="text-left">
                        {{ mhs.mahasiswa.nama_mahasiswa }}
                      </td>
                      <td class="text-center" v-for="cp in cpmk" :key="cp.id">
                        <span
                          v-if="
                            kelasKuliahCPMK.filter(
                              (e) =>
                                e.uid_mahasiswa === mhs.uid_mahasiswa &&
                                e.cpmk_id === cp.id
                            ).length > 0
                          "
                        >
                          <span v-for="kkc in kelasKuliahCPMK" :key="kkc.id">
                            <span
                              v-if="
                                kkc.uid_mahasiswa === mhs.uid_mahasiswa &&
                                kkc.cpmk_id === cp.id
                              "
                            >
                              <span
                                v-if="kkc.bobot > (kkc.cpmk_bobot * 50) / 100"
                                class="black--text"
                              >
                                {{ kkc.bobot }} %
                              </span>
                              <span v-else class="red--text">
                                {{ kkc.bobot }} %
                              </span>
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr bgcolor="lightgrey">
                      <td colspan="3" class="text-center">
                        <b>RATA-RATA PENCAPAIAN CPMK DALAM MK</b>
                      </td>
                      <td class="text-center" v-for="cp in cpmk" :key="cp.id">
                        <b>
                          <span
                            v-if="
                              kelasKuliahCPMKtotal.filter(
                                (e) => e.cpmk_id === cp.id
                              ).length > 0
                            "
                          >
                            <span
                              v-for="kkc in kelasKuliahCPMKtotal"
                              :key="kkc.id"
                            >
                              <span v-if="kkc.cpmk_id === cp.id">
                                {{ kkc.total_bobot }} %
                              </span>
                            </span>
                          </span>
                          <span v-else> - </span></b
                        >
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
              <v-divider></v-divider>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EntriEvaluasiDetail",
  data: () => ({
    loading: false,
    details: null,
    uid_kelas_kuliah: null,
    uid_mata_kuliah: null,
    kode_mk: null,
    nama_mk: null,
    kelas_mk: null,
    tahun_ajaran: null,
    sem: null,
    koordinator: null,
    datas: [],
    cpmk: [],
    monitoringRae: [],
    kelasKuliahCPMK: [],
    kelasKuliahCPMKtotal: [],
    totalIku: null,
    totalNonIku: null,
    semesterSelect: parseInt(localStorage.getItem("semesterSelect")),

    uri: "data:application/vnd.ms-excel;base64,",
    template:
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64: function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format: function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    },

    series: [],
    chartOptions: [],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    rules() {
      return [(v) => !!v || ""];
    },
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getMahasiswaMkByKodeMk();
      this.getCpmkByKodeMk();
    },

    getMahasiswaMkByKodeMk() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getKelasKuliahByMk/" +
            this.semesterSelect +
            "/" +
            this.$route.params.kelas +
            "/" +
            this.$route.params.id,
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.details = response.data.data;
          this.mode = response.data.data[0].kategori_penilaian;
          this.modeTampil = response.data.data[0].kategori_penilaian;
          this.datas = response.data.data[0].mahasiswa;
          this.uid_kelas_kuliah = response.data.data[0].uuid;
          this.uid_mata_kuliah = response.data.data[0].uid_mata_kuliah;
          this.kode_mk = response.data.data[0].mata_kuliah.kode_mata_kuliah;
          this.nama_mk = response.data.data[0].mata_kuliah.nama_mata_kuliah_id;
          this.kelas_mk = response.data.data[0].kelas;
          this.tahun_ajaran = response.data.data[0].semester.tahun_ajaran;
          this.sem = response.data.data[0].semester.semester;
          this.statusTombol = response.data.data[0].status;
          this.koordinator = response.data.data[0].mata_kuliah.koordinator;
          if (this.statusTombol == 0) {
            this.status = false;
          } else {
            this.status = true;
            this.namaDosen = response.data.data[0].log.created_by.nama;
            this.tglPermanen = response.data.data[0].log.created_at;
          }
          console.log(this.details);

          this.getMonitoringCpmk(this.uid_kelas_kuliah, this.uid_mata_kuliah);
          this.getTotalMonitoringCpmk(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          this.getMonitoringRae(this.uid_mata_kuliah);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getCpmkByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getCpmkByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          this.cpmk = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getMonitoringCpmk(uid_kelas_kuliah, uid_mata_kuliah) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getMonitoringCpmk/" +
            uid_kelas_kuliah +
            "/" +
            uid_mata_kuliah,
          config
        )
        .then((response) => {
          this.kelasKuliahCPMK = response.data.data;
          // console.log(this.kelasKuliahCPMK);
        })
        .catch(() => {
          this.kelasKuliahCPMK = [];
        });
    },

    getTotalMonitoringCpmk(uid_kelas_kuliah, uid_mata_kuliah) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getTotalMonitoringCpmk/" +
            uid_kelas_kuliah +
            "/" +
            uid_mata_kuliah,
          config
        )
        .then((response) => {
          this.kelasKuliahCPMKtotal = response.data.data;
          // console.log(this.kelasKuliahCPMK);
        })
        .catch(() => {
          this.kelasKuliahCPMKtotal = [];
        });
    },

    getMonitoringRae(uid_mata_kuliah) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getMonitoringRae/" + uid_mata_kuliah, config)
        .then((response) => {
          this.monitoringRae = response.data.data[0];
          this.totalIku = response.data.data[1].total_iku;
          this.totalNonIku = response.data.data[1].total_noniku;
          // console.log(this.totalIku);
          this.series = [this.totalIku, this.totalNonIku];

          this.chartOptions = {
            chart: {
              width: 350,
              type: "pie",
            },
            colors: ["#FF4560", "#008FFB"],
            labels: ["IKU 7", "Non IKU"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch(() => {
          this.monitoringRae = [];
        });
    },

    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },
  },
};
</script>

<style lang="css" scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: 10px !important;
  display: flex !important;
  align-items: center !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  border: 1px solid grey;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  text-align: right;
}
</style>