<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            MASTER &raquo;
            {{ $t("master.dosen").toUpperCase() }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="datas"
        class="elevation-1 table"
        item-key="name"
        :search="search"
      >
        <template v-slot:item.nomor="{ item }">
          {{ datas.indexOf(item) + 1 }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("master.dosen") }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon> </v-btn
                >&nbsp;
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click="dialogExcel = true"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedIndex > -1"
                        >
                          <v-autocomplete
                            v-model="editedItem.prodi.jurusan.kode_fakultas"
                            :items="fakultasItems"
                            :rules="rules"
                            item-value="kode_fakultas"
                            item-text="nama_fakultas_id"
                            label="Fakultas"
                            clearable
                            single-line
                            dense
                            v-on:change="fakultasChange"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-else>
                          <v-autocomplete
                            v-model="tambah_kode_fakultas"
                            :items="fakultasItems"
                            :rules="rules"
                            item-value="kode_fakultas"
                            item-text="nama_fakultas_id"
                            label="Fakultas"
                            clearable
                            single-line
                            dense
                            v-on:change="fakultasChange"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          v-if="editedIndex > -1"
                        >
                          <v-autocomplete
                            v-model="editedItem.prodi.jurusan.kode_jurusan"
                            :items="jurusanItems"
                            :rules="rules"
                            item-value="kode_jurusan"
                            item-text="nama_jurusan_id"
                            label="Jurusan"
                            clearable
                            single-line
                            dense
                            v-on:change="jurusanChange"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-else>
                          <v-autocomplete
                            v-model="tambah_kode_jurusan"
                            :items="jurusanItems"
                            :rules="rules"
                            item-value="kode_jurusan"
                            item-text="nama_jurusan_id"
                            label="Jurusan"
                            clearable
                            single-line
                            dense
                            v-on:change="jurusanChange"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.kode_prodi"
                            :items="prodiItems"
                            :rules="rules"
                            item-value="kode_prodi"
                            item-text="nama_prodi_id"
                            label="Program Studi"
                            clearable
                            single-line
                            dense
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.jenjang }} - {{ item.nama_prodi_id }}
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item.jenjang }} - {{ item.nama_prodi_id }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nidn"
                            :rules="rules"
                            label="NIDN"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nip"
                            :rules="rules"
                            label="NIP"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nama"
                            :rules="rules"
                            label="Nama"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.no_telpon"
                            :rules="rules"
                            label="No Telpon"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.email"
                            :rules="rules"
                            label="Email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.jenis_kelamin"
                            :rules="rules"
                            label="Jenis Kelamin"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.tempat_lahir"
                            :rules="rules"
                            label="Tempat Lahir"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.tanggal_lahir"
                            :rules="rules"
                            type="date"
                            label="Tanggal Lahir"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.gelar"
                            label="Gelar"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.gelar_depan"
                            label="Gelar Depan"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.gelar_belakang"
                            label="Gelar Belakang"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("tabel.batal") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                    >
                      {{ $t("tabel.simpan") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Reset password dosen ini menjadi NIDN ?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("tabel.batal")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("tabel.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            :label="$t('tabel.cari')"
            class="mx-4 pt-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <v-icon small color="warning" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon> -->
          <v-icon small color="info" @click="deleteItem(item)">
            mdi-lock-reset
          </v-icon>
        </template>
        <template v-slot:no-data> {{ $t("tabel.tidak_ada_data") }} </template>
      </v-data-table>
    </v-container>

    <v-dialog v-model="dialogExcel" width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Upload Excel Dosen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="tambah_kode_fakultas"
                  :items="fakultasItems"
                  :rules="rules"
                  item-value="kode_fakultas"
                  item-text="nama_fakultas_id"
                  label="Fakultas"
                  clearable
                  single-line
                  dense
                  v-on:change="fakultasChange"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="tambah_kode_jurusan"
                  :items="jurusanItems"
                  :rules="rules"
                  item-value="kode_jurusan"
                  item-text="nama_jurusan_id"
                  label="Jurusan"
                  clearable
                  single-line
                  dense
                  v-on:change="jurusanChange"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="tambah_kode_prodi"
                  :items="prodiItems"
                  :rules="rules"
                  item-value="kode_prodi"
                  item-text="nama_prodi_id"
                  label="Program Studi"
                  clearable
                  single-line
                  dense
                >
                  <template v-slot:selection="{ item }">
                    {{ item.jenjang }} - {{ item.nama_prodi_id }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.jenjang }} - {{ item.nama_prodi_id }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label
                  >File Excel :
                  <input
                    type="file"
                    id="file"
                    ref="file"
                    v-on:change="handleFileUpload()"
                  /> </label
                ><br /><br />
                Download contoh Format Excel Dosen
                <a href="/format/dosen.xlsx">disini.</a>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="dialogExcel = false"> Tutup </v-btn>
          <v-btn color="info" text @click="submitFile"> Upload </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Dosen",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogExcel: false,
    valid: true,
    tambah_kode_fakultas: null,
    tambah_kode_jurusan: null,
    tambah_kode_prodi: null,
    search: "",
    datas: [],
    fakultasItems: [],
    jurusanItems: [],
    prodiItems: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        { text: "No", value: "nomor" },
        { text: "NIDN", value: "nidn" },
        { text: "NIP", value: "nip" },
        { text: "Nama", value: "nama" },
        { text: "Email", value: "email" },
        { text: "Jenis Kelamin", value: "jenis_kelamin" },
        { text: "Fakultas", value: "prodi.jurusan.fakultas.nama_fakultas_id" },
        { text: "Jurusan", value: "prodi.jurusan.nama_jurusan_id" },
        { text: "Prodi", value: "prodi.nama_prodi_id" },
        { text: this.$t("tabel.aksi"), value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getDosen();
      this.getFakultas();
    },

    getDosen() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/dosenList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            this.datas = response.data.data;
          } else {
            var Data = response.data.data;
            var newData = Data.filter(
              (e) => e.kode_prodi == this.user.data.aktif.kode_prodi
            );
            this.datas = newData;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getFakultas() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/fakultasList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            this.fakultasItems = response.data.data;
          } else {
            var Data = response.data.data;
            var newData = Data.filter(
              (e) => e.kode_fakultas == this.user.data.aktif.kode_fakultas
            );
            this.fakultasItems = newData;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    fakultasChange: function (e) {
      this.getJurusan(e);
    },

    getJurusan(item) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/jurusanList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            var Data = response.data.data;
            var newData = Data.filter((e) => e.kode_fakultas == item);
            this.jurusanItems = newData;
          } else {
            var Data2 = response.data.data;
            var newData2 = Data2.filter(
              (e) => e.kode_jurusan == this.user.data.aktif.kode_jurusan
            );
            this.jurusanItems = newData2;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    jurusanChange: function (e) {
      this.getProdi(e);
    },

    getProdi(item) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/prodiList", config)
        .then((response) => {
          this.$vloading.hide();
          if (this.user.data.aktif.role == "1") {
            var Data = response.data.data;
            var newData = Data.filter((e) => e.kode_jurusan == item);
            this.prodiItems = newData;
          } else {
            var Data2 = response.data.data;
            var newData2 = Data2.filter(
              (e) => e.kode_prodi == this.user.data.aktif.kode_prodi
            );
            this.prodiItems = newData2;
          }
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.getJurusan(item.prodi.jurusan.kode_fakultas);
      this.getProdi(item.prodi.jurusan.kode_jurusan);
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("username", this.editedItem.nidn);
      this.axios
        .post("/api/auth/resetPassword", formData, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: "Password berhasil di reset menjadi NIDN !",
          });
          this.close();
          this.getDosen();
        })
        .catch((error) => {
          this.$vloading.hide();
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set(
            "kode_fakultas",
            this.editedItem.prodi.jurusan.kode_fakultas
          );
          formData.set(
            "kode_jurusan",
            this.editedItem.prodi.jurusan.kode_jurusan
          );
          formData.set("kode_prodi", this.editedItem.kode_prodi);
          formData.set("nama", this.editedItem.nama);
          formData.set("nidn", this.editedItem.nidn);
          formData.set("nip", this.editedItem.nip);
          formData.set("no_telpon", this.editedItem.no_telpon);
          formData.set("email", this.editedItem.email);
          formData.set("jenis_kelamin", this.editedItem.jenis_kelamin);
          formData.set("tempat_lahir", this.editedItem.tempat_lahir);
          formData.set("tanggal_lahir", this.editedItem.tanggal_lahir);
          formData.set("gelar", this.editedItem.gelar);
          formData.set("gelar_depan", this.editedItem.gelar_depan);
          formData.set("gelar_belakang", this.editedItem.gelar_belakang);
          this.axios
            .post("/api/auth/saveDosen/" + this.editedItem.id, formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.ubah"),
              });
              this.close();
              this.getDosen();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        } else {
          // CREATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("kode_fakultas", this.tambah_kode_fakultas);
          formData.set("kode_jurusan", this.tambah_kode_jurusan);
          formData.set("kode_prodi", this.editedItem.kode_prodi);
          formData.set("nama", this.editedItem.nama);
          formData.set("nidn", this.editedItem.nidn);
          formData.set("nip", this.editedItem.nip);
          formData.set("no_telpon", this.editedItem.no_telpon);
          formData.set("email", this.editedItem.email);
          formData.set("jenis_kelamin", this.editedItem.jenis_kelamin);
          formData.set("tempat_lahir", this.editedItem.tempat_lahir);
          formData.set("tanggal_lahir", this.editedItem.tanggal_lahir);
          formData.set("gelar", this.editedItem.gelar);
          formData.set("gelar_depan", this.editedItem.gelar_depan);
          formData.set("gelar_belakang", this.editedItem.gelar_belakang);
          this.axios
            .post("/api/auth/saveDosen", formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.tambah"),
              });
              this.close();
              this.getDosen();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    closes() {
      this.$emit("closed", false);
    },

    submitFile() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.set("kode_fakultas", this.tambah_kode_fakultas);
      formData.set("kode_jurusan", this.tambah_kode_jurusan);
      formData.set("kode_prodi", this.tambah_kode_prodi);
      formData.set("excel", this.file);
      this.axios
        .post("/api/auth/importDosen", formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Upload berhasil!",
          });
          this.dialogExcel = false;
          this.$refs.file.value = null;
          this.getDosen();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>

<style lang="css" scoped>
.table >>> th {
  font-size: 9pt !important;
}
.table >>> tr > td {
  font-size: 10pt !important;
}

.table >>> .v-data-footer__select,
.table >>> .v-select__selection,
.table >>> .v-data-footer__pagination {
  font-size: 9pt;
}
</style>