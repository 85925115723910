<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("kurikulum.kurikulum").toUpperCase() }} &raquo;
            {{ $t("mk.daftar_mk").toUpperCase() }} &raquo;
            {{ $t("mk.pengampu_mk").toUpperCase() }}
          </div>
        </v-col>
        <br />
        <v-col cols="12" v-for="dt in datasMK" :key="dt.id">
          <v-card tile elevation="1" class="pa-4 text-body-2">
            <v-chip label color="primary"
              ><b>{{ dt.kode_mata_kuliah }}</b></v-chip
            >
            <table border="0" style="width: 100%; padding-top: 4pt">
              <tr>
                <td style="width: 50%">
                  <span class="primary--text">{{
                    dt.nama_mata_kuliah_id
                  }}</span>
                </td>
                <td style="width: 25%">Jenis mata kuliah</td>
                <td style="width: 25%">: {{ dt.jenis_mk.nama_jenis_mk_id }}</td>
              </tr>
              <tr>
                <td>
                  <i>{{ dt.nama_mata_kuliah_en }}</i>
                </td>
                <td>Kategori</td>
                <td>
                  : <span v-if="dt.kategori_mata_kuliah == '1'"> MKK </span
                  ><span v-else>MKWU</span>
                </td>
                <!-- <td>Nilai minimum lulus</td>
                <td>: {{ dt.nilai_minimum }}</td> -->
              </tr>
              <tr>
                <td>{{ dt.sks_mata_kuliah }} sks</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Koordinator :
                  <span
                    v-if="
                      dt.koordinator == '' ||
                      dt.koordinator == 0 ||
                      dt.koordinator == null
                    "
                    >-</span
                  ><span v-else>{{ dt.koordinator.nama }}</span>
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </v-card>
          <v-alert text tile class="blue-grey lighten-5"
            ><h4>Deskripsi Mata Kuliah</h4>
            <div class="py-2">
              <span
                v-if="
                  dt.deskripsi_id == '' ||
                  dt.deskripsi_id == 0 ||
                  dt.deskripsi_id == null
                "
                >Belum ada deskripsi.</span
              ><span v-else>{{ dt.deskripsi_id }}</span>
            </div>
            <v-divider></v-divider>
            <div class="py-2">
              <i
                ><span
                  v-if="
                    dt.deskripsi_en == '' ||
                    dt.deskripsi_en == 0 ||
                    dt.deskripsi_en == null
                  "
                  >No description yet.</span
                ><span v-else>{{ dt.deskripsi_en }}</span></i
              >
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="datas"
        class="elevation-1"
        item-key="name"
        :search="search"
      >
        <template v-slot:item.nomor="{ item }">
          {{ datas.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.dosen.prodi.nama_prodi_id="{ item }">
          <span v-if="item.dosen.prodi.jenjang == 'A'"> S3 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'B'"> S2 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'C'"> S1 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'D'"> D4 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'E'"> D3 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'F'"> D2 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'G'"> D1 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'H'"> Sp-1 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'I'"> Sp-2 </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'J'"> PROFESI </span>
          <span v-else-if="item.dosen.prodi.jenjang == 'X'">
            NON AKADEMIK
          </span>
          - {{ item.dosen.prodi.nama_prodi_id }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("mk.pengampu_mk") }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-autocomplete
                            v-model="editedItem.nidn"
                            :items="dosenItems"
                            :rules="rules"
                            item-value="dosen.nidn"
                            item-text="dosen.nama"
                            label="Dosen"
                            clearable
                            single-line
                            dense
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.dosen.nidn }} - {{ item.dosen.nama }}
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item.dosen.nidn }} - {{ item.dosen.nama }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("tabel.batal") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                    >
                      {{ $t("tabel.simpan") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("tabel.dialog_hapus")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("tabel.batal")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("tabel.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            :label="$t('tabel.cari')"
            class="mx-4 pt-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small color="warning" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small color="error" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data> {{ $t("tabel.tidak_ada_data") }} </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MataKuliahPengampu",
  data: () => ({
    datasMK: [],
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    datas: [],
    dosenItems: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    kode_semester: null,
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        { text: "No", value: "nomor" },
        { text: "NIDN", value: "nidn" },
        { text: "Nama Dosen", value: "dosen.nama" },
        { text: "Program Studi", value: "dosen.prodi.nama_prodi_id" },
        { text: this.$t("tabel.aksi"), value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getMataKuliah();
      this.getMkPengampu();
      this.getDosenProdi();
    },

    getMataKuliah() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getMataKuliah/" + this.$route.params.id, config)
        .then((response) => {
          this.$vloading.hide();
          this.datasMK = response.data.data;
          this.kode_semester = response.data.data[0].kurikulum.kode_semester;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getMkPengampu() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getMkPengampuByMk/" + this.$route.params.id, config)
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getDosenProdi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/hakAksesList", config)
        .then((response) => {
          this.$vloading.hide();
          // this.dosenItems = response.data.data;
          if (this.user.data.aktif.role == "1") {
            this.dosenItems = response.data.data;
          } else {
            var Data2 = response.data.data;
            var newData2 = Data2.filter(
              (e) =>
                e.kode_prodi == this.user.data.aktif.kode_prodi && e.role == "5"
            );
            this.dosenItems = newData2;
          }
          // console.log(this.dosenItems);
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyMkPengampu/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.hapus"),
          });
          this.close();
          this.getMkPengampu();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("nidn", this.editedItem.nidn);
          formData.set("uid_mata_kuliah", this.$route.params.id);
          formData.set("kode_semester", this.kode_semester);
          this.axios
            .post(
              "/api/auth/saveMkPengampu/" + this.editedItem.id,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.ubah"),
              });
              this.close();
              this.getMkPengampu();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        } else {
          // CREATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("nidn", this.editedItem.nidn);
          formData.set("uid_mata_kuliah", this.$route.params.id);
          formData.set("kode_semester", this.kode_semester);
          this.axios
            .post("/api/auth/saveMkPengampu", formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.tambah"),
              });
              this.close();
              this.getMkPengampu();
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>