<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("penilaian.penilaian").toUpperCase() }} &raquo;
            {{ $t("penilaian.evaluasi_pembelajaran").toUpperCase() }} &raquo;
            DETAIL
          </div>
        </v-col>
        <br />
        <v-col cols="12" v-for="dt in details" :key="dt.id">
          <v-card tile elevation="1" class="pa-4 text-body-2">
            <table border="0" style="width: 100%; padding-top: 4pt">
              <tr>
                <td class="text-left">Mata Kuliah</td>
                <td class="text-right">
                  <b
                    >{{ dt.mata_kuliah.kode_mata_kuliah }} -
                    {{ dt.mata_kuliah.nama_mata_kuliah_id }}</b
                  >
                </td>
              </tr>
              <tr>
                <td class="text-left">Semester</td>
                <td class="text-right">
                  <b>{{ dt.semester.nama }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left">Kelas</td>
                <td class="text-right">
                  <b>{{ dt.kelas }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left">Jumlah Mahasiswa</td>
                <td class="text-right">
                  <b>{{ dt.jumlah_mahasiswa }}</b>
                </td>
              </tr>
              <tr>
                <td class="text-left" valign="top">
                  Pengajar<br /><b>
                    <span
                      v-for="pengajar in dt.mata_kuliah.pengampu"
                      :key="pengajar.id"
                    >
                      {{ dt.mata_kuliah.pengampu.indexOf(pengajar) + 1 }}.
                      {{ pengajar.dosen.nama }}
                      <v-chip
                        v-if="koordinator == pengajar.dosen.nidn"
                        color="warning"
                        x-small
                        label
                        class="mb-1"
                      >
                        Koordinator </v-chip
                      ><br />
                    </span>
                  </b>
                </td>
                <td class="text-right"></td>
              </tr>
            </table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="10 offset-1">
          <v-card tile class="pa-4">
            <v-card-text class="black--text">
              <b>Mode Pengisian Nilai</b><br />
              Saya ingin mengisi nilai berdasarkan:
              <v-radio-group v-model="mode">
                <v-radio
                  value="1"
                  :disabled="status"
                  @click="dialogMode = true"
                >
                  <template v-slot:label>
                    <div class="black--text">Komponen Evaluasi</div>
                  </template>
                </v-radio>
                <v-radio
                  value="2"
                  :disabled="status"
                  @click="dialogMode = true"
                >
                  <template v-slot:label>
                    <div class="black--text">CPMK</div>
                  </template></v-radio
                >
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mode Penilaian RAE -->
    <v-container v-if="modeTampil == 1">
      <v-row>
        <v-col cols="12">
          <v-card tile :loading="loading">
            <v-card-text class="black--text">
              <v-card-actions>
                <h3>Pengisian Nilai per Komponen Evaluasi</h3>
                <v-spacer></v-spacer>

                <v-icon left>mdi-eye</v-icon>

                <v-btn
                  small
                  depressed
                  @click="tampilan(2)"
                  color="grey lighten-2"
                >
                  <v-icon class="ml-n2" color="grey darken-1"
                    >mdi-page-layout-sidebar-left</v-icon
                  >
                  Komponen
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <br />
              <v-simple-table class="table" dense v-if="mode == 1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">#</th>
                      <th class="text-center">NIM</th>
                      <th class="text-center">Nama Mahasiswa</th>
                      <th class="text-center" v-for="ra in rae" :key="ra.id">
                        {{ ra.rae_id }}<br />({{ ra.rae_cpmk_count }}%)
                      </th>
                      <th class="text-center">NA</th>
                      <th class="text-center">NH</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="mhs in datas" :key="mhs.id">
                      <td class="text-center">{{ datas.indexOf(mhs) + 1 }}</td>
                      <td class="text-center">{{ mhs.mahasiswa.nim }}</td>
                      <td class="text-left">
                        {{ mhs.mahasiswa.nama_mahasiswa }}
                      </td>
                      <td class="text-center" v-for="ra in rae" :key="ra.id">
                        <span
                          v-if="
                            kelasKuliahRAE.filter(
                              (e) =>
                                e.uid_mahasiswa === mhs.uid_mahasiswa &&
                                e.rae_id === ra.id
                            ).length > 0
                          "
                        >
                          <span v-for="kkr in kelasKuliahRAE" :key="kkr.id">
                            <span
                              v-if="
                                kkr.uid_mahasiswa === mhs.uid_mahasiswa &&
                                kkr.rae_id === ra.id
                              "
                            >
                              <v-text-field
                                v-if="isEditingKelasKuliahRAE == kkr.id"
                                v-model.number="kkr.bobot"
                                :disabled="status"
                                type="number"
                                min="0"
                                max="100"
                                outlined
                                dense
                                solo
                                reverse
                                class="mt-2 mb-n5 inputPrice"
                                @blur="
                                  updateKelasKuliahRAE(
                                    mhs.uid_mahasiswa +
                                      '_' +
                                      mhs.nim +
                                      '_' +
                                      ra.id +
                                      '_' +
                                      kkr.bobot +
                                      '_' +
                                      kkr.id
                                  )
                                "
                              ></v-text-field>
                              <v-tooltip bottom v-else :disabled="status">
                                <template v-slot:activator="{ on, attrs }">
                                  <input
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model.number="kkr.bobot"
                                    style="background-color: lightgrey"
                                    type="number"
                                    min="0"
                                    max="100"
                                    @dblclick="isEditingKelasKuliahRAE = kkr.id"
                                    readonly
                                    v-if="status == false"
                                  />
                                  <input
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model.number="kkr.bobot"
                                    style="background-color: lightgrey"
                                    type="number"
                                    min="0"
                                    max="100"
                                    readonly
                                    v-else
                                  />
                                </template>
                                <span>Klik 2 kali untuk melakukan edit</span>
                              </v-tooltip>
                            </span>
                          </span>
                        </span>
                        <span v-else>
                          <input
                            v-model="ra[mhs.id]"
                            type="number"
                            min="0"
                            max="100"
                            @blur="
                              insertKelasKuliahRAE(
                                mhs.uid_mahasiswa +
                                  '_' +
                                  mhs.nim +
                                  '_' +
                                  ra.id +
                                  '_' +
                                  ra[mhs.id]
                              )
                            "
                          />
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahRAE.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkr in bobotKelasKuliahRAE"
                            :key="bkkr.id"
                          >
                            <span
                              v-if="bkkr.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkr.total_bobot }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahRAE.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkr in bobotKelasKuliahRAE"
                            :key="bkkr.id"
                          >
                            <span
                              v-if="bkkr.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkr.hasil.simbol }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table class="table" dense v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">#</th>
                      <th class="text-center">NIM</th>
                      <th class="text-center">Nama Mahasiswa</th>
                      <th class="text-center" v-for="ra in rae" :key="ra.id">
                        {{ ra.rae_id }}<br />({{ ra.rae_cpmk_count }}%)
                      </th>
                      <th class="text-center">NA</th>
                      <th class="text-center">NH</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="mhs in datas" :key="mhs.id">
                      <td class="text-center">{{ datas.indexOf(mhs) + 1 }}</td>
                      <td class="text-center">{{ mhs.mahasiswa.nim }}</td>
                      <td class="text-left">
                        {{ mhs.mahasiswa.nama_mahasiswa }}
                      </td>
                      <td class="text-center" v-for="ra in rae" :key="ra.id">
                        <span
                          v-if="
                            kelasKuliahRAE.filter(
                              (e) =>
                                e.uid_mahasiswa === mhs.uid_mahasiswa &&
                                e.rae_id === ra.id
                            ).length > 0
                          "
                        >
                          <span v-for="kkr in kelasKuliahRAE" :key="kkr.id">
                            <span
                              v-if="
                                kkr.uid_mahasiswa === mhs.uid_mahasiswa &&
                                kkr.rae_id === ra.id
                              "
                            >
                              {{ kkr.bobot }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahRAE.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkr in bobotKelasKuliahRAE"
                            :key="bkkr.id"
                          >
                            <span
                              v-if="bkkr.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkr.total_bobot }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahRAE.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkr in bobotKelasKuliahRAE"
                            :key="bkkr.id"
                          >
                            <span
                              v-if="bkkr.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkr.hasil.simbol }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider></v-divider>
              <v-card-actions v-if="mode == 1">
                <v-spacer></v-spacer>

                <v-btn
                  small
                  @click="refresh(2)"
                  color="info"
                  v-if="statusTombol == 0"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  Simpan Nilai
                </v-btn>
              </v-card-actions>
              <b v-if="mode == 1">Pengisian Nilai Melalui Berkas Excel</b><br />
              <ol v-if="mode == 1">
                <li class="ma-2">
                  Unduh template Excel mode Komponen Evaluasi
                  <v-btn
                    small
                    color="success"
                    dark
                    outlined
                    @click="tableToExcel('table', 'Download Tabel')"
                  >
                    <v-icon left>mdi-download</v-icon>
                    Unduh
                  </v-btn>
                </li>
                <li class="ma-2">
                  Entrikan nilai ke dalam template Excel yang telah diunduh.
                </li>
                <li class="ma-2">
                  Unggah template Excel yang sudah terisi nilai disini:<br />

                  <!-- <label
                    >File Excel :
                  </label> -->
                  <input
                    v-if="statusTombol == 0"
                    type="file"
                    id="file2"
                    ref="file2"
                    v-on:change="handleFileUpload2()"
                    style="background-color: lightgrey; margin-top: 10px"
                  /><br />
                  <v-btn
                    v-if="statusTombol == 0"
                    small
                    color="success"
                    dark
                    class="mt-2"
                    @click="submitFileRae"
                  >
                    <v-icon left>mdi-upload</v-icon>
                    Unggah
                  </v-btn>
                </li>
              </ol>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Mode Penilaian CPMK -->
    <v-container v-if="modeTampil == 2">
      <v-row>
        <v-col cols="12">
          <v-card tile :loading="loading">
            <v-card-text class="black--text">
              <v-card-actions>
                <h3>Pengisian Nilai per CPMK</h3>
                <v-spacer></v-spacer>

                <v-icon left>mdi-eye</v-icon>
                <v-btn
                  small
                  depressed
                  @click="tampilan(1)"
                  color="grey lighten-2"
                >
                  CPMK
                  <v-icon class="mr-n2" color="grey darken-1"
                    >mdi-page-layout-sidebar-right</v-icon
                  >
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <br />
              <v-simple-table class="table" dense v-if="mode == 2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">#</th>
                      <th class="text-center">NIM</th>
                      <th class="text-center">Nama Mahasiswa</th>
                      <th class="text-center" v-for="cp in cpmk" :key="cp.id">
                        <v-tooltip bottom color="info" max-width="20%">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="blue--text"
                              >CPMK-{{ cp.kode_cpmk }} ({{
                                cp.bobot_cpmk
                              }}%)</span
                            >
                          </template>
                          <span><b>Deskripsi:</b><br />{{ cp.cpmk_id }}</span>
                        </v-tooltip>
                      </th>
                      <th class="text-center">NA</th>
                      <th class="text-center">NH</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="mhs in datas" :key="mhs.id">
                      <td class="text-center">{{ datas.indexOf(mhs) + 1 }}</td>
                      <td class="text-center">{{ mhs.mahasiswa.nim }}</td>
                      <td class="text-left">
                        {{ mhs.mahasiswa.nama_mahasiswa }}
                      </td>
                      <td class="text-center" v-for="cp in cpmk" :key="cp.id">
                        <span
                          v-if="
                            kelasKuliahCPMK.filter(
                              (e) =>
                                e.uid_mahasiswa === mhs.uid_mahasiswa &&
                                e.cpmk_id === cp.id
                            ).length > 0
                          "
                        >
                          <span v-for="kkc in kelasKuliahCPMK" :key="kkc.id">
                            <span
                              v-if="
                                kkc.uid_mahasiswa === mhs.uid_mahasiswa &&
                                kkc.cpmk_id === cp.id
                              "
                            >
                              <v-text-field
                                v-if="isEditingKelasKuliahCPMK == kkc.id"
                                v-model.number="kkc.bobot"
                                :disabled="status"
                                type="number"
                                min="0"
                                max="100"
                                outlined
                                dense
                                solo
                                reverse
                                class="mt-2 mb-n5 inputPrice"
                                @blur="
                                  updateKelasKuliahCpmk(
                                    mhs.uid_mahasiswa +
                                      '_' +
                                      mhs.nim +
                                      '_' +
                                      cp.id +
                                      '_' +
                                      kkc.bobot +
                                      '_' +
                                      kkc.id
                                  )
                                "
                              ></v-text-field>
                              <v-tooltip bottom v-else :disabled="status">
                                <template v-slot:activator="{ on, attrs }">
                                  <input
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model.number="kkc.bobot"
                                    style="background-color: lightgrey"
                                    type="number"
                                    min="0"
                                    max="100"
                                    @dblclick="
                                      isEditingKelasKuliahCPMK = kkc.id
                                    "
                                    readonly
                                    v-if="status == false"
                                  />
                                  <input
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model.number="kkc.bobot"
                                    style="background-color: lightgrey"
                                    type="number"
                                    min="0"
                                    max="100"
                                    readonly
                                    v-else
                                  />
                                </template>
                                <span>Klik 2 kali untuk melakukan edit</span>
                              </v-tooltip>
                            </span>
                          </span>
                        </span>
                        <span v-else>
                          <input
                            v-model="cp[mhs.id]"
                            type="number"
                            min="0"
                            max="100"
                            @blur="
                              insertKelasKuliahCpmk(
                                mhs.uid_mahasiswa +
                                  '_' +
                                  mhs.nim +
                                  '_' +
                                  cp.id +
                                  '_' +
                                  cp[mhs.id]
                              )
                            "
                          />
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahCPMK.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkc in bobotKelasKuliahCPMK"
                            :key="bkkc.id"
                          >
                            <span
                              v-if="bkkc.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkc.total_bobot }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahCPMK.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkc in bobotKelasKuliahCPMK"
                            :key="bkkc.id"
                          >
                            <span
                              v-if="bkkc.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkc.hasil.simbol }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table class="table" dense v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">#</th>
                      <th class="text-center">NIM</th>
                      <th class="text-center">Nama Mahasiswa</th>
                      <th class="text-center" v-for="cp in cpmk" :key="cp.id">
                        <v-tooltip bottom color="info" max-width="20%">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="blue--text"
                              >CPMK-{{ cp.kode_cpmk }} ({{
                                cp.bobot_cpmk
                              }}%)</span
                            >
                          </template>
                          <span><b>Deskripsi:</b><br />{{ cp.cpmk_id }}</span>
                        </v-tooltip>
                      </th>
                      <th class="text-center">NA</th>
                      <th class="text-center">NH</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="mhs in datas" :key="mhs.id">
                      <td class="text-center">{{ datas.indexOf(mhs) + 1 }}</td>
                      <td class="text-center">{{ mhs.mahasiswa.nim }}</td>
                      <td class="text-left">
                        {{ mhs.mahasiswa.nama_mahasiswa }}
                      </td>
                      <td class="text-center" v-for="cp in cpmk" :key="cp.id">
                        <span
                          v-if="
                            kelasKuliahCPMK.filter(
                              (e) =>
                                e.uid_mahasiswa === mhs.uid_mahasiswa &&
                                e.cpmk_id === cp.id
                            ).length > 0
                          "
                        >
                          <span v-for="kkc in kelasKuliahCPMK" :key="kkc.id">
                            <span
                              v-if="
                                kkc.uid_mahasiswa === mhs.uid_mahasiswa &&
                                kkc.cpmk_id === cp.id
                              "
                            >
                              {{ kkc.bobot }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahCPMK.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkc in bobotKelasKuliahCPMK"
                            :key="bkkc.id"
                          >
                            <span
                              v-if="bkkc.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkc.total_bobot }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            bobotKelasKuliahCPMK.filter(
                              (e) => e.uid_mahasiswa === mhs.uid_mahasiswa
                            ).length > 0
                          "
                        >
                          <span
                            v-for="bkkc in bobotKelasKuliahCPMK"
                            :key="bkkc.id"
                          >
                            <span
                              v-if="bkkc.uid_mahasiswa == mhs.uid_mahasiswa"
                            >
                              {{ bkkc.hasil.simbol }}
                            </span>
                          </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider></v-divider>
              <v-card-actions v-if="mode == 2">
                <v-spacer></v-spacer>

                <v-btn
                  small
                  @click="refresh(1)"
                  color="info"
                  v-if="statusTombol == 0"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  Simpan Nilai
                </v-btn>
              </v-card-actions>
              <b v-if="mode == 2">Pengisian Nilai Melalui Berkas Excel</b><br />
              <ol v-if="mode == 2">
                <li class="ma-2">
                  Unduh template Excel mode CPMK
                  <v-btn
                    small
                    color="success"
                    dark
                    outlined
                    @click="tableToExcel('table', 'Download Tabel')"
                  >
                    <v-icon left>mdi-download</v-icon>
                    Unduh
                  </v-btn>
                </li>
                <li class="ma-2">
                  Entrikan nilai ke dalam template Excel yang telah diunduh.
                </li>
                <li class="ma-2">
                  Unggah template Excel yang sudah terisi nilai disini:<br />

                  <!-- <label
                    >File Excel :
                  </label> -->
                  <input
                    v-if="statusTombol == 0"
                    type="file"
                    id="file"
                    ref="file"
                    v-on:change="handleFileUpload()"
                    style="background-color: lightgrey; margin-top: 10px"
                  /><br />
                  <v-btn
                    v-if="statusTombol == 0"
                    small
                    color="success"
                    dark
                    class="mt-2"
                    @click="submitFile"
                  >
                    <v-icon left>mdi-upload</v-icon>
                    Unggah
                  </v-btn>
                </li>
              </ol>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Simpan Permanen -->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card
            tile
            elevation="1"
            class="pa-4 text-body-2"
            v-if="statusTombol == 0"
          >
            <span
              v-if="
                (kelasKuliahCPMK.length == datas.length * cpmk.length &&
                  cpmk.length != 0) ||
                (kelasKuliahRAE.length == datas.length * rae.length &&
                  rae.length != 0)
              "
              :hidden="status"
            >
              <v-btn color="warning" tile block @click="dialogPermanen = true"
                ><v-icon left>mdi-lock</v-icon> Simpan Nilai Permanen</v-btn
              >
            </span>
            <span v-else>
              <v-btn color="warning" disabled tile block
                ><v-icon left>mdi-lock</v-icon> Simpan Permanen</v-btn
              >
              <span style="color: red"
                >*Lihat kembali form penilaian, pastikan semua form terisi
                dengan angka.</span
              ></span
            >
          </v-card>
          <v-card tile elevation="1" class="pa-4 mt-n2" v-else>
            <v-alert outlined type="success" class="text-body-2 mb-n3" text>
              Nilai telah dipermanen oleh <b>{{ namaDosen }}</b> pada
              {{ tglPermanen | moment("dddd, DD MMMM YYYY, H:mm") }}
              WITA.<br />
              Dosen dapat membatalkan permanen secara mandiri hingga
              {{ jadwal_tutup | moment("dddd, DD MMMM YYYY, H:mm") }}
              WITA.</v-alert
            ><br />
            <!-- {{ dateNow }} {{ jadwal_tutup }} -->
            <v-btn
              v-if="
                Date.parse(dateNow) / 1000 < Date.parse(jadwal_tutup) / 1000
              "
              color="error"
              tile
              block
              @click="dialogPermanenBatal = true"
              ><v-icon left>mdi-lock-open-variant</v-icon> Batalkan
              Permanen</v-btn
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br /><br />

    <!-- Excel Penilaian RAE -->
    <div id="print" v-if="modeTampil == 1" hidden>
      <center>
        <table
          ref="table"
          id="loremTable"
          border="0"
          width="95%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
            margin-top: 2px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td align="left" bgcolor="yellow">
              <b
                >Mohon untuk tidak mengubah urutan mahasiswa, tidak mengubah
                komposisi evaluasi, dan tidak mengisikan data apapun di luar
                area pengisian nilai.<br />
                {{ uid_kelas_kuliah }}#{{ uid_mata_kuliah }}
                <br />
                {{ kode_mk }} - {{ nama_mk }} - Kelas: {{ kelas_mk }} - Program
                Studi:<span v-if="user.data.aktif.prodi.jenjang == 'A'">
                  S3
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'B'">
                  S2
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'C'">
                  S1
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'D'">
                  D4
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'E'">
                  D3
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'F'">
                  D2
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'G'">
                  D1
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'H'">
                  Sp-1
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'I'">
                  Sp-2
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'J'">
                  PROFESI
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'X'">
                  NON AKADEMIK
                </span>
                {{ user.data.aktif.prodi.nama_prodi_id }} - Tahun:
                {{ tahun_ajaran }} - Sem: {{ sem }}
              </b>
            </td>
          </tr>
          <tr>
            <td align="left">
              <table
                ref="table"
                id="loremTable"
                border="1"
                width="100%"
                style="border-collapse: collapse; font-size: 18px"
                align="center"
                cellpadding="5px"
              >
                <tr>
                  <th>No.</th>
                  <th>NIM</th>
                  <th>Nama Mahasiswa</th>
                  <th class="text-center" v-for="ra in rae" :key="ra.id">
                    {{ ra.rae_id }} ({{ ra.rae_cpmk_count }}%)
                  </th>
                </tr>
                <tr
                  v-for="(mhs, index) in datas"
                  :key="index"
                  style="vertical-align: top"
                >
                  <td align="center">{{ index + 1 }}</td>
                  <td align="center">
                    {{ mhs.mahasiswa.nim }}
                  </td>
                  <td>
                    {{ mhs.mahasiswa.nama_mahasiswa }}
                  </td>
                  <td class="text-right" v-for="ra in rae" :key="ra.id">
                    <span
                      v-if="
                        kelasKuliahRAE.filter(
                          (e) =>
                            e.uid_mahasiswa === mhs.uid_mahasiswa &&
                            e.rae_id === ra.id
                        ).length > 0
                      "
                    >
                      <span v-for="kkr in kelasKuliahRAE" :key="kkr.id">
                        <span
                          v-if="
                            kkr.uid_mahasiswa === mhs.uid_mahasiswa &&
                            kkr.rae_id === ra.id
                          "
                        >
                          {{ kkr.bobot }}
                        </span>
                      </span>
                    </span>
                    <span v-else> 0 </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </center>
    </div>
    <!-- Excel Penilaian CPMK -->
    <div id="print" v-if="modeTampil == 2" hidden>
      <center>
        <table
          ref="table"
          id="loremTable"
          border="0"
          width="95%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
            margin-top: 2px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td align="left" bgcolor="yellow">
              <b
                >Mohon untuk tidak mengubah urutan mahasiswa, tidak mengubah
                komposisi evaluasi, dan tidak mengisikan data apapun di luar
                area pengisian nilai.<br />
                {{ uid_kelas_kuliah }}#{{ uid_mata_kuliah }}
                <br />
                {{ kode_mk }} - {{ nama_mk }} - Kelas: {{ kelas_mk }} - Program
                Studi:<span v-if="user.data.aktif.prodi.jenjang == 'A'">
                  S3
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'B'">
                  S2
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'C'">
                  S1
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'D'">
                  D4
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'E'">
                  D3
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'F'">
                  D2
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'G'">
                  D1
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'H'">
                  Sp-1
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'I'">
                  Sp-2
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'J'">
                  PROFESI
                </span>
                <span v-else-if="user.data.aktif.prodi.jenjang == 'X'">
                  NON AKADEMIK
                </span>
                {{ user.data.aktif.prodi.nama_prodi_id }} - Tahun:
                {{ tahun_ajaran }} - Sem: {{ sem }}
              </b>
            </td>
          </tr>
          <tr>
            <td align="left">
              <table
                ref="table"
                id="loremTable"
                border="1"
                width="100%"
                style="border-collapse: collapse; font-size: 18px"
                align="center"
                cellpadding="5px"
              >
                <tr>
                  <th>No.</th>
                  <th>NIM</th>
                  <th>Nama Mahasiswa</th>
                  <th class="text-center" v-for="cp in cpmk" :key="cp.id">
                    CPMK-{{ cp.kode_cpmk }} ({{ cp.bobot_cpmk }}%)
                  </th>
                </tr>
                <tr
                  v-for="(mhs, index) in datas"
                  :key="index"
                  style="vertical-align: top"
                >
                  <td align="center">{{ index + 1 }}</td>
                  <td align="center">
                    {{ mhs.mahasiswa.nim }}
                  </td>
                  <td>
                    {{ mhs.mahasiswa.nama_mahasiswa }}
                  </td>
                  <td class="text-right" v-for="cp in cpmk" :key="cp.id">
                    <span
                      v-if="
                        kelasKuliahCPMK.filter(
                          (e) =>
                            e.uid_mahasiswa === mhs.uid_mahasiswa &&
                            e.cpmk_id === cp.id
                        ).length > 0
                      "
                    >
                      <span v-for="kkc in kelasKuliahCPMK" :key="kkc.id">
                        <span
                          v-if="
                            kkc.uid_mahasiswa === mhs.uid_mahasiswa &&
                            kkc.cpmk_id === cp.id
                          "
                        >
                          {{ kkc.bobot }}
                        </span>
                      </span>
                    </span>
                    <span v-else> 0 </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </center>
    </div>

    <v-dialog v-model="dialogMode" max-width="800px" persistent>
      <v-card class="py-4">
        <v-card-title class="justify-center">
          <span class="headline font-weight-medium">Konfirmasi</span>
        </v-card-title>
        <v-card-text class="text-center font-weight-medium">
          <span v-if="mode == 1">
            Anda akan mengubah mode pengisian nilai dari <b>CPMK</b> menjadi
            <b>Komponen Evaluasi</b>. Data penilaian yang sudah diisikan di mode
            <b>CPMK</b> akan dihapus. Apakah Anda ingin melanjutkan?
          </span>
          <span v-if="mode == 2">
            Anda akan mengubah mode pengisian nilai dari
            <b>Komponen Evaluasi</b> menjadi <b>CPMK</b>. Data penilaian yang
            sudah diisikan di mode <b>Komponen Evaluasi</b> akan dihapus. Apakah
            Anda ingin melanjutkan?
          </span>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="grey darken-1"
            class="white--text"
            @click="
              getMahasiswaMkByKodeMk();
              dialogMode = false;
            "
          >
            {{ $t("tabel.batal") }}
          </v-btn>
          <v-btn color="info" @click="pilihMode">
            Ya, saya ingin mengubah
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPermanen" max-width="800px">
      <v-card class="py-4">
        <v-card-title class="justify-center">
          <span class="headline font-weight-medium">Simpan Nilai Permanen</span>
        </v-card-title>
        <v-card-text class="text-center font-weight-medium">
          Apakah Anda yakin untuk menyimpan <b>permanen</b> perubahan nilai
          kelas {{ kode_mk }} - {{ nama_mk }} [{{ kelas_mk }}]?<br />
          Nilai tidak dapat diubah lagi selanjutnya.
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="success" @click="simpanPermanen">
            Simpan Permanen
          </v-btn>
          <v-btn
            color="grey darken-1"
            class="white--text"
            @click="dialogPermanen = false"
          >
            {{ $t("tabel.batal") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPermanenBatal" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="justify-center">
          <span class="headline font-weight-medium">Apakah Anda yakin?</span>
        </v-card-title>
        <v-card-text class="text-center font-weight-medium">
          Status nilai permanen Mata Kuliah akan dibatalkan!
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="error" @click="simpanPermanenBatal">
            Batalkan Permanen
          </v-btn>
          <v-btn
            color="grey darken-1"
            class="white--text"
            @click="dialogPermanenBatal = false"
          >
            {{ $t("tabel.batal") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EntriEvaluasiDetail",
  data: () => ({
    statusTombol: 0,
    switch1: true,
    hidden: true,
    status: false,
    loading: false,
    mode: null,
    modeTampil: null,
    dateNow: null,
    details: null,
    uid_kelas_kuliah: null,
    uid_mata_kuliah: null,
    kode_mk: null,
    nama_mk: null,
    kelas_mk: null,
    tahun_ajaran: null,
    sem: null,
    koordinator: null,
    jadwal_tutup: null,
    isEditingKelasKuliahCPMK: null,
    isEditingKelasKuliahRAE: null,
    dialogMode: false,
    dialogPermanen: false,
    dialogPermanenBatal: false,
    datas: [],
    cpmk: [],
    rae: [],
    kelasKuliahCPMK: [],
    bobotKelasKuliahCPMK: [],
    kelasKuliahRAE: [],
    bobotKelasKuliahRAE: [],
    semesterSelect: parseInt(localStorage.getItem("semesterSelect")),

    uri: "data:application/vnd.ms-excel;base64,",
    template:
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64: function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format: function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    },
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    rules() {
      return [(v) => !!v || ""];
    },
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getMahasiswaMkByKodeMk();
      this.getCpmkByKodeMk();
      this.getRaeByKodeMk();
      this.getJadwalObe();
      this.getDateTime();
    },

    getDateTime() {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (hour.toString().length == 1) {
        hour = "0" + hour;
      }
      if (minute.toString().length == 1) {
        minute = "0" + minute;
      }
      if (second.toString().length == 1) {
        second = "0" + second;
      }
      this.dateNow =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
    },

    pilihMode() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("kategori", this.mode);
      formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
      this.axios
        .post(
          "/api/auth/updateKelasKuliahKategori/" + this.uid_kelas_kuliah,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: "Berhasil mengubah Mode Pengisian Nilai!",
          });
          this.initialize();
          this.dialogMode = false;
          this.modeTampil = this.mode;
        })
        .catch((error) => {
          this.$vloading.hide();
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    tampilan(id) {
      this.modeTampil = id;
      // if (id == 1) {
      //   // console.log("rae");
      //   this.$vloading.show();
      //   let config = {
      //     headers: {
      //       Authorization: "Bearer " + this.user.data.token,
      //     },
      //   };
      //   this.axios
      //     .get(
      //       "/api/auth/getBobotKelasKuliahByKKMhs/" +
      //         this.uid_kelas_kuliah +
      //         "/" +
      //         this.uid_mata_kuliah +
      //         "/rae",
      //       config
      //     )
      //     .then(() => {
      //       // console.log(response);
      //       this.$vloading.hide();
      //       this.getKelasKuliahCpmkByKKMK(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //       this.getTotalBobotKelasKuliahCpmkByKKMhs(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //       this.getKelasKuliahRaeByKKMK(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //       this.getTotalBobotKelasKuliahRaeByKKMhs(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //     })
      //     .catch((error) => {
      //       this.$vloading.hide();
      //       this.setAlert({
      //         status: true,
      //         color: "error",
      //         text: error.response.data.message,
      //       });
      //       this.close();
      //     });
      // } else {
      //   // console.log("cpmk");
      //   this.$vloading.show();
      //   let config = {
      //     headers: {
      //       Authorization: "Bearer " + this.user.data.token,
      //     },
      //   };
      //   this.axios
      //     .get(
      //       "/api/auth/getBobotKelasKuliahByKKMhs/" +
      //         this.uid_kelas_kuliah +
      //         "/" +
      //         this.uid_mata_kuliah +
      //         "/cpmk",
      //       config
      //     )
      //     .then(() => {
      //       // console.log(response);
      //       this.$vloading.hide();
      //       this.getKelasKuliahCpmkByKKMK(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //       this.getTotalBobotKelasKuliahCpmkByKKMhs(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //       this.getKelasKuliahRaeByKKMK(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //       this.getTotalBobotKelasKuliahRaeByKKMhs(
      //         this.uid_kelas_kuliah,
      //         this.uid_mata_kuliah
      //       );
      //     })
      //     .catch((error) => {
      //       this.$vloading.hide();
      //       this.setAlert({
      //         status: true,
      //         color: "error",
      //         text: error.response.data.message,
      //       });
      //       this.close();
      //     });
      // }
    },

    refresh(id) {
      if (id == 1) {
        // console.log("rae");
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        this.axios
          .get(
            "/api/auth/refreshBobotKelasKuliahByKKMhs/" +
              this.uid_kelas_kuliah +
              "/" +
              this.uid_mata_kuliah +
              "/rae",
            config
          )
          .then(() => {
            // console.log(response);
            this.$vloading.hide();
            this.getKelasKuliahCpmkByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahCpmkByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getKelasKuliahRaeByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahRaeByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
          })
          .catch((error) => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
            this.close();
          });
      } else {
        // console.log("cpmk");
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        this.axios
          .get(
            "/api/auth/refreshBobotKelasKuliahByKKMhs/" +
              this.uid_kelas_kuliah +
              "/" +
              this.uid_mata_kuliah +
              "/cpmk",
            config
          )
          .then(() => {
            // console.log(response);
            this.$vloading.hide();
            this.getKelasKuliahCpmkByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahCpmkByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getKelasKuliahRaeByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahRaeByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
          })
          .catch((error) => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
            this.close();
          });
      }
    },

    getJadwalObe() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/jadwalObeList", config)
        .then((response) => {
          this.$vloading.hide();
          this.jadwal_tutup = response.data.data[1].waktu_tutup;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getMahasiswaMkByKodeMk() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getKelasKuliahByMk/" +
            this.semesterSelect +
            "/" +
            this.$route.params.kelas +
            "/" +
            this.$route.params.id,
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.details = response.data.data;
          this.mode = response.data.data[0].kategori_penilaian;
          this.modeTampil = response.data.data[0].kategori_penilaian;
          this.datas = response.data.data[0].mahasiswa;
          this.uid_kelas_kuliah = response.data.data[0].uuid;
          this.uid_mata_kuliah = response.data.data[0].uid_mata_kuliah;
          this.kode_mk = response.data.data[0].mata_kuliah.kode_mata_kuliah;
          this.nama_mk = response.data.data[0].mata_kuliah.nama_mata_kuliah_id;
          this.kelas_mk = response.data.data[0].kelas;
          this.tahun_ajaran = response.data.data[0].semester.tahun_ajaran;
          this.sem = response.data.data[0].semester.semester;
          this.statusTombol = response.data.data[0].status;
          this.koordinator = response.data.data[0].mata_kuliah.koordinator;
          if (this.statusTombol == 0) {
            this.status = false;
          } else {
            this.status = true;
            this.namaDosen = response.data.data[0].log.created_by.nama;
            this.tglPermanen = response.data.data[0].log.created_at;
          }
          console.log(this.details);

          this.getDateTime();
          this.getKelasKuliahCpmkByKKMK(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          this.getTotalBobotKelasKuliahCpmkByKKMhs(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          this.getKelasKuliahRaeByKKMK(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          this.getTotalBobotKelasKuliahRaeByKKMhs(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getCpmkByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getCpmkByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          this.cpmk = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getRaeByKodeMk() {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getRaeByKodeMk/" + this.$route.params.id, config)
        .then((response) => {
          // this.$vloading.hide();
          this.rae = response.data.data;
        })
        .catch((error) => {
          // this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    getKelasKuliahRaeByKKMK(uid_kelas_kuliah, uid_mata_kuliah) {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getKelasKuliahRaeByKKMK/" +
            uid_kelas_kuliah +
            "/" +
            uid_mata_kuliah,
          config
        )
        .then((response) => {
          // this.$vloading.hide();
          this.kelasKuliahRAE = response.data.data;
          // console.log(this.kelasKuliahCPMK);
        })
        .catch(() => {
          this.kelasKuliahRAE = [];
          // this.$vloading.hide();
          // this.setAlert({
          //   status: true,
          //   color: "error",
          //   text: error.response.data.message,
          // });
        });
    },

    getTotalBobotKelasKuliahRaeByKKMhs(uid_kelas_kuliah, uid_mata_kuliah) {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getTotalBobotKelasKuliahRaeByKKMhs/" +
            uid_kelas_kuliah +
            "/" +
            uid_mata_kuliah,
          config
        )
        .then((response) => {
          // this.$vloading.hide();
          this.bobotKelasKuliahRAE = response.data.data;
          // console.log(this.bobotKelasKuliahCPMK);
        })
        .catch(() => {
          this.bobotKelasKuliahRAE = [];
          // this.$vloading.hide();
          // this.setAlert({
          //   status: true,
          //   color: "error",
          //   text: error.response.data.message,
          // });
        });
    },

    getKelasKuliahCpmkByKKMK(uid_kelas_kuliah, uid_mata_kuliah) {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getKelasKuliahCpmkByKKMK/" +
            uid_kelas_kuliah +
            "/" +
            uid_mata_kuliah,
          config
        )
        .then((response) => {
          // this.$vloading.hide();
          this.kelasKuliahCPMK = response.data.data;
          // console.log(this.kelasKuliahCPMK);
        })
        .catch(() => {
          this.kelasKuliahCPMK = [];
          // this.$vloading.hide();
          // this.setAlert({
          //   status: true,
          //   color: "error",
          //   text: error.response.data.message,
          // });
        });
    },

    getTotalBobotKelasKuliahCpmkByKKMhs(uid_kelas_kuliah, uid_mata_kuliah) {
      // this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getTotalBobotKelasKuliahCpmkByKKMhs/" +
            uid_kelas_kuliah +
            "/" +
            uid_mata_kuliah,
          config
        )
        .then((response) => {
          // this.$vloading.hide();
          this.bobotKelasKuliahCPMK = response.data.data;
          // console.log(this.bobotKelasKuliahCPMK);
        })
        .catch(() => {
          this.bobotKelasKuliahCPMK = [];
          // this.$vloading.hide();
          // this.setAlert({
          //   status: true,
          //   color: "error",
          //   text: error.response.data.message,
          // });
        });
    },

    insertKelasKuliahRAE(item) {
      // console.log(item);
      // console.log(this.uid_kelas_kuliah);
      // console.log(this.uid_mata_kuliah);
      const sel = item.split("_");
      const uid_mahasiswa = sel[0];
      const nim = sel[1];
      const rae_id = sel[2];
      const bobot = sel[3];
      // console.log(uid_mahasiswa);

      if (isNaN(bobot)) {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
        formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
        formData.set("uid_mahasiswa", uid_mahasiswa);
        formData.set("nim", nim);
        formData.set("rae_id", rae_id);
        formData.set("bobot", "0");
        this.axios
          .post("/api/auth/saveKelasKuliahRae", formData, config)
          .then(() => {
            this.getKelasKuliahRaeByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahRaeByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            // this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      } else {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
        formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
        formData.set("uid_mahasiswa", uid_mahasiswa);
        formData.set("nim", nim);
        formData.set("rae_id", rae_id);
        formData.set("bobot", bobot);
        this.axios
          .post("/api/auth/saveKelasKuliahRae", formData, config)
          .then(() => {
            this.getKelasKuliahRaeByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahRaeByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            // this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    updateKelasKuliahRAE(item) {
      // console.log(item);
      const sel = item.split("_");
      const uid_mahasiswa = sel[0];
      const nim = sel[1];
      const rae_id = sel[2];
      const bobot = sel[3];
      const id = sel[4];
      // console.log(uid_mahasiswa);

      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
      formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
      formData.set("uid_mahasiswa", uid_mahasiswa);
      formData.set("nim", nim);
      formData.set("rae_id", rae_id);
      formData.set("bobot", bobot);
      this.axios
        .post("/api/auth/saveKelasKuliahRae/" + id, formData, config)
        .then(() => {
          this.getKelasKuliahRaeByKKMK(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          this.getTotalBobotKelasKuliahRaeByKKMhs(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          // this.snackbar = true;
          this.loading = false;
          this.isEditingKelasKuliahRAE = null;
        })
        .catch((error) => {
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    insertKelasKuliahCpmk(item) {
      // console.log(item);
      // console.log(this.uid_kelas_kuliah);
      // console.log(this.uid_mata_kuliah);
      const sel = item.split("_");
      const uid_mahasiswa = sel[0];
      const nim = sel[1];
      const cpmk_id = sel[2];
      const bobot = sel[3];
      // console.log(uid_mahasiswa);

      if (isNaN(bobot)) {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
        formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
        formData.set("uid_mahasiswa", uid_mahasiswa);
        formData.set("nim", nim);
        formData.set("cpmk_id", cpmk_id);
        formData.set("bobot", "0");
        this.axios
          .post("/api/auth/saveKelasKuliahCpmk", formData, config)
          .then(() => {
            this.getKelasKuliahCpmkByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahCpmkByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            // this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      } else {
        this.loading = true;
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
        formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
        formData.set("uid_mahasiswa", uid_mahasiswa);
        formData.set("nim", nim);
        formData.set("cpmk_id", cpmk_id);
        formData.set("bobot", bobot);
        this.axios
          .post("/api/auth/saveKelasKuliahCpmk", formData, config)
          .then(() => {
            this.getKelasKuliahCpmkByKKMK(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            this.getTotalBobotKelasKuliahCpmkByKKMhs(
              this.uid_kelas_kuliah,
              this.uid_mata_kuliah
            );
            // this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    updateKelasKuliahCpmk(item) {
      // console.log(item);
      const sel = item.split("_");
      const uid_mahasiswa = sel[0];
      const nim = sel[1];
      const cpmk_id = sel[2];
      const bobot = sel[3];
      const id = sel[4];
      // console.log(uid_mahasiswa);

      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
      formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
      formData.set("uid_mahasiswa", uid_mahasiswa);
      formData.set("nim", nim);
      formData.set("cpmk_id", cpmk_id);
      formData.set("bobot", bobot);
      this.axios
        .post("/api/auth/saveKelasKuliahCpmk/" + id, formData, config)
        .then(() => {
          this.getKelasKuliahCpmkByKKMK(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          this.getTotalBobotKelasKuliahCpmkByKKMhs(
            this.uid_kelas_kuliah,
            this.uid_mata_kuliah
          );
          // this.snackbar = true;
          this.loading = false;
          this.isEditingKelasKuliahCPMK = null;
        })
        .catch((error) => {
          this.loading = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    simpanPermanen() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("status", "1");
      this.axios
        .post(
          "/api/auth/updateKelasKuliahStatus/" + this.uid_kelas_kuliah,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: "Berhasil melakukan simpan dipermanenkan!",
          });
          this.dialogPermanen = false;
          this.getMahasiswaMkByKodeMk();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.dialogPermanen = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    simpanPermanenBatal() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      let formData = new FormData();
      formData.set("status", "0");
      this.axios
        .post(
          "/api/auth/updateKelasKuliahStatus/" + this.uid_kelas_kuliah,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: "Status permanen Mata Kuliah berhasil dibatalkan!",
          });
          this.dialogPermanenBatal = false;
          this.getMahasiswaMkByKodeMk();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.dialogPermanenBatal = false;
          let responses = error.response;
          console.log(responses);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
        });
    },

    tableToExcel(table, name) {
      if (!table.nodeType) table = this.$refs.table;
      var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
      window.location.href =
        this.uri + this.base64(this.format(this.template, ctx));
    },

    submitFile() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
      formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
      formData.set("excel", this.file);
      this.axios
        .post("/api/auth/importKelasKuliahCpmk", formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Upload berhasil!",
          });
          this.$refs.file.value = null;
          this.initialize();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    submitFileRae() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.set("uid_kelas_kuliah", this.uid_kelas_kuliah);
      formData.set("uid_mata_kuliah", this.uid_mata_kuliah);
      formData.set("excel", this.file2);
      this.axios
        .post("/api/auth/importKelasKuliahRae", formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Upload berhasil!",
          });
          this.$refs.file2.value = null;
          this.initialize();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    handleFileUpload2() {
      this.file2 = this.$refs.file2.files[0];
    },
  },
};
</script>

<style lang="css" scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: 10px !important;
  display: flex !important;
  align-items: center !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  border: 1px solid grey;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  text-align: right;
}
</style>