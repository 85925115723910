<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            SETTING &raquo;
            {{ $t("master.ubah_password").toUpperCase() }}
          </div>
        </v-col>
        <br />
        <v-col cols="12">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                $t("master.ubah_password")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-container fluid class="pa-10">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row align="center">
                  <v-col cols="12">
                    <v-text-field
                      v-model="password_lama"
                      :append-icon="
                        showPasswordLama ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :rules="passwordRules"
                      :type="showPasswordLama ? 'text' : 'password'"
                      label="Password Lama"
                      counter
                      @click:append="showPasswordLama = !showPasswordLama"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="passwordRules"
                      :type="showPassword ? 'text' : 'password'"
                      label="Password Baru"
                      counter
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="confpassword"
                      :append-icon="
                        showconfPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :rules="
                        confpasswordRules.concat(passwordConfirmationRule)
                      "
                      :type="showconfPassword ? 'text' : 'password'"
                      label="Konfirmasi Password Baru"
                      counter
                      @click:append="showconfPassword = !showconfPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-center">
                      <v-btn color="success" :disabled="!valid" @click="update">
                        <v-icon left dark>mdi-update</v-icon>
                        Ubah Password Aplikasi OBE
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SyncDosen",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    token: "",
    prodiSelect: "",
    prodiItems: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    showPasswordLama: false,
    password_lama: "",
    showPassword: false,
    password: "",
    showconfPassword: false,
    confpassword: "",
    passwordRules: [
      (v) => !!v || "Password wajib di isi",
      (v) => (v && v.length >= 8) || "Minimal 8 karakter",
    ],
    confpasswordRules: [
      (v) => !!v || "Konfirmasi Password wajib di isi",
      (v) => (v && v.length >= 8) || "Minimal 8 karakter",
    ],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        { text: "No", value: "nomor" },
        { text: "Kode", value: "kode_cpl", width: "10%" },
        { text: this.$t("cpl.cpl_id"), value: "cpl_id" },
        { text: this.$t("cpl.cpl_en"), value: "cpl_en" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {},

    update() {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("password", this.password_lama);
        formData.set("password_baru", this.password);
        formData.set("password_baru_confirmation", this.password);
        this.axios
          .post("/api/auth/gantiPassword", formData, config)
          .then(() => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil Update!",
            });
            this.close();
          })
          .catch((error) => {
            this.$vloading.hide();
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>