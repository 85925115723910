<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("sync.sync").toUpperCase() }} &raquo;
            {{ $t("sync.dosen").toUpperCase() }}
          </div>
        </v-col>
        <br />
        <v-col cols="12">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title
                >{{ $t("sync.sync") }} {{ $t("sync.dosen") }}</v-toolbar-title
              >
            </v-toolbar>
            <v-divider></v-divider>
            <v-container fluid class="pa-10">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row align="center">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="prodiSelect"
                      :items="prodiItems"
                      :rules="rules"
                      item-value="kode_prodi"
                      item-text="nama_prodi_id"
                      :label="$t('master.prodi')"
                      clearable
                      single-line
                      dense
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.nama_prodi_id }} - {{ item.jenjang }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.nama_prodi_id }} - {{ item.jenjang }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="token"
                      :rules="rules"
                      dense
                      label="Token"
                      rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-center">
                      <v-btn color="success" :disabled="!valid" @click="insert">
                        <v-icon left dark>mdi-plus-circle-outline</v-icon>
                        Insert </v-btn
                      >&nbsp;
                      <v-btn color="warning" :disabled="!valid" @click="update">
                        <v-icon left dark>mdi-update</v-icon>
                        Update
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SyncDosen",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    token: "",
    prodiSelect: "",
    prodiItems: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        { text: "No", value: "nomor" },
        { text: "Kode", value: "kode_cpl", width: "10%" },
        { text: this.$t("cpl.cpl_id"), value: "cpl_id" },
        { text: this.$t("cpl.cpl_en"), value: "cpl_en" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getProdi();
    },

    getProdi() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/prodiList", config)
        .then((response) => {
          this.$vloading.hide();
          this.prodiItems = response.data.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete("/api/auth/destroyCpl/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.hapus"),
          });
          this.close();
          this.getCPL(parseInt(localStorage.getItem("prodiSelect")));
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    insert() {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("kode_prodi", this.prodiSelect);
        formData.set("token", this.token);
        this.axios
          .post("/api/siakad/insertDosen", formData, config)
          .then(() => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil Insert!",
            });
            this.close();
          })
          .catch((error) => {
            this.$vloading.hide();
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    update() {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("kode_prodi", this.prodiSelect);
        formData.set("token", this.token);
        this.axios
          .post("/api/siakad/updateDosen", formData, config)
          .then(() => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "success",
              text: "Berhasil Update!",
            });
            this.close();
          })
          .catch((error) => {
            this.$vloading.hide();
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    closes() {
      this.$emit("closed", false);
    },
  },
};
</script>