<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="grey--text text-body-1">
            {{ $t("penilaian.penilaian").toUpperCase() }} &raquo;
            {{ $t("penilaian.evaluasi_pembelajaran").toUpperCase() }}
          </div>
        </v-col>
        <br />
        <v-col cols="10 offset-1">
          <v-form ref="form" v-model="validKurikulum" lazy-validation>
            <v-card tile elevation="1" class="pa-2">
              <v-container fluid>
                <v-row align="center">
                  <v-col cols="12" md="2" lg="2" align="center">
                    <label> {{ $t("penilaian.semester") }}: </label>
                  </v-col>
                  <v-col cols="12" md="8" lg="8">
                    <v-autocomplete
                      v-model="semesterSelect"
                      :items="semesterItems"
                      :rules="rules"
                      item-value="kode_semester"
                      item-text="nama"
                      :label="$t('penilaian.semester')"
                      clearable
                      single-line
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="2" lg="2" align="center">
                    <v-btn
                      v-if="user.data.aktif.role == '5'"
                      color="primary"
                      class="mr-4"
                      @click="getMataKuliahDosen(semesterSelect)"
                      :disabled="!validKurikulum"
                    >
                      {{ $t("tombol.tampilkan") }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      class="mr-4"
                      @click="getMataKuliah(semesterSelect)"
                      :disabled="!validKurikulum"
                    >
                      {{ $t("tombol.tampilkan") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="datas"
        class="elevation-1"
        item-key="name"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.mata_kuliah.nama_mata_kuliah_id="{ item }">
          <router-link
            :to="
              `/${$i18n.locale}` +
              '/penilaian/entri-evaluasi/' +
              item.mata_kuliah.uuid +
              '/' +
              item.kelas
            "
            style="text-decoration: none"
            >{{ item.mata_kuliah.nama_mata_kuliah_id }} <br />
            <i>{{ item.mata_kuliah.nama_mata_kuliah_en }}</i></router-link
          >
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="item.status == 0">
            <v-chip class="ma-2" label color="warning" small>
              {{ $t("status.belum_permanen") }}
            </v-chip>
          </span>
          <span v-else>
            <v-chip class="ma-2" label color="success" small>
              {{ $t("status.permanen") }}
            </v-chip>
          </span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{
                $t("penilaian.evaluasi_pembelajaran")
              }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="700px">
              <!-- <template
                v-slot:activator="{ on, attrs }"
                v-if="user.data.aktif.role != '5'"
              >
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon> </v-btn
                >&nbsp;
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  @click="dialogExcel = true"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.kode_mata_kuliah"
                            :rules="rules"
                            :label="$t('mk.kode_mk')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nama_mata_kuliah_id"
                            :rules="rules"
                            :label="$t('mk.nama_mk')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nama_mata_kuliah_en"
                            :rules="rules"
                            :label="$t('mk.nama_mk_en')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.sks"
                            :rules="rules"
                            :label="$t('mk.sks')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.nilai_minimum"
                            :rules="rules"
                            :label="$t('mk.minimum')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.tahun"
                            :rules="rules"
                            type="number"
                            :label="$t('mk.tahun')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="editedItem.bentuk_mk_id"
                            :items="bentukItems"
                            :rules="rules"
                            item-value="id"
                            item-text="nama_bentuk_mk_id"
                            :label="$t('master.bentuk_mk')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="editedItem.jenis_mk_id"
                            :items="jenisItems"
                            :rules="rules"
                            item-value="id"
                            item-text="nama_jenis_mk_id"
                            :label="$t('master.jenis_mk')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="editedItem.kategori_mata_kuliah"
                            :items="kategoriItems"
                            :rules="rules"
                            item-value="id"
                            item-text="kategori"
                            label="Kategori Mata Kuliah"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t("tabel.batal") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!valid"
                      text
                      @click="save"
                    >
                      {{ $t("tabel.simpan") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("tabel.dialog_hapus")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("tabel.batal")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("tabel.ok") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-text-field
            v-model="search"
            :label="$t('tabel.cari')"
            class="mx-4 pt-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <span v-if="user.data.aktif.role != '5'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :to="
                    `/${$i18n.locale}` +
                    '/kurikulum/mata-kuliah/pengampu/' +
                    item.kode_mata_kuliah
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small color="blue">
                    mdi-human-male-board-poll
                  </v-icon>
                </v-btn>
              </template>
              <span>Team Teaching</span> </v-tooltip
            >&nbsp;
            <v-icon small color="warning" @click="editItem(item)">
              mdi-pencil </v-icon
            >&nbsp;
            <v-icon
              small
              color="error"
              v-if="item.status != '1'"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </span>
        </template>
        <template v-slot:no-data> {{ $t("tabel.tidak_ada_data") }} </template>
      </v-data-table>
    </v-container>

    <v-dialog v-model="dialogKoor" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Koordinator Mata Kuliah</span>
        </v-card-title>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="koorItem.koordinator"
                    :items="dosenItems"
                    :rules="rules"
                    item-value="nidn"
                    item-text="dosen.nama"
                    label="Koordinator Mata Kuliah"
                    clearable
                    single-line
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.dosen.nama }} - {{ item.nidn }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ item.dosen.nama }} - {{ item.nidn }}
                    </template>
                  </v-autocomplete>
                  <span style="color: orange"
                    ><small
                      >*Silahkan mengisi team teaching mata kuliah terlebih
                      dahulu sebelum memilih koordinator
                    </small></span
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeKoor">
              {{ $t("tabel.batal") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              :disabled="!valid"
              text
              @click="saveKoor(koorItem)"
            >
              {{ $t("tabel.simpan") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogExcel" width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Upload Excel Mata Kuliah</span>
        </v-card-title>
        <v-card-text>
          <br />
          <label
            >File Excel :
            <input
              type="file"
              id="file"
              ref="file"
              v-on:change="handleFileUpload()"
            /> </label
          ><br /><br />
          Download contoh Format Excel Mata Kuliah
          <a href="/format/matakuliah.xlsx">disini.</a><br /><br />
          <table
            border="1"
            width="50%"
            style="
              border-collapse: collapse;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 10pt;
              margin-top: 2px;
            "
            align="center"
            cellpadding="3px"
          >
            <thead>
              <tr>
                <th colspan="2">Master Kategori Mata Kuliah</th>
              </tr>
              <tr>
                <th>ID</th>
                <th>Kategori</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center">1</td>
                <td align="center">MKK</td>
              </tr>
              <tr>
                <td align="center">2</td>
                <td align="center">MKWU</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="dialogExcel = false"> Tutup </v-btn>
          <v-btn color="info" text @click="submitFile"> Upload </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "EntriEvaluasi",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogKoor: false,
    dialogExcel: false,
    koorItem: {},
    valid: true,
    validKurikulum: true,
    search: "",
    datas: [],
    semesterSelect: parseInt(localStorage.getItem("semesterSelect")),
    semesterItems: [],
    bentukItems: [],
    jenisItems: [],
    dosenItems: [],
    kategoriItems: [
      { id: "1", kategori: "MKK" },
      { id: "2", kategori: "MKWU" },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("tabel.tambah")
        : this.$t("tabel.edit");
    },
    headers() {
      return [
        {
          text: this.$t("kurikulum.kurikulum"),
          value: "mata_kuliah.kurikulum.nama_kurikulum_id",
          align: "center",
        },
        {
          text: this.$t("mk.kode_mk"),
          value: "mata_kuliah.kode_mata_kuliah",
          align: "center",
        },
        {
          text: this.$t("mk.nama_mk"),
          value: "mata_kuliah.nama_mata_kuliah_id",
        },
        { text: this.$t("mk.sks"), value: "mata_kuliah.sks_mata_kuliah" },
        { text: this.$t("penilaian.kelas"), value: "kelas" },
        { text: this.$t("penilaian.status_penilaian"), value: "status" },
        // { text: "Actions", value: "actions", sortable: false },
      ];
    },
    rules() {
      return [(v) => !!v || this.$t("tabel.required")];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
    }),

    initialize() {
      this.getSemester();
      this.getBentukMK();
      this.getJenisMK();
      if (this.user.data.aktif.role == "5") {
        this.getMataKuliahDosen(
          parseInt(localStorage.getItem("semesterSelect"))
        );
      } else {
        this.getMataKuliah(parseInt(localStorage.getItem("semesterSelect")));
      }
    },

    getMataKuliah(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/kelasKuliahList/" +
            this.user.data.aktif.kode_prodi +
            "/" +
            id,
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
          localStorage.semesterSelect = this.semesterSelect;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getMataKuliahDosen(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get(
          "/api/auth/getKelasKuliahByDosen/" +
            this.user.data.aktif.kode_prodi +
            "/" +
            id +
            "/" +
            this.user.data.username,
          config
        )
        .then((response) => {
          this.$vloading.hide();
          this.datas = response.data.data;
          localStorage.semesterSelect = this.semesterSelect;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getSemester() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/semesterList", config)
        .then((response) => {
          this.$vloading.hide();
          // this.semesterItems = response.data.data;
          var Data = response.data.data;
          var newData = Data.filter((e) => e.aktif == 1);
          this.semesterItems = newData;
          
          this.semesterSelect = parseInt(
            localStorage.getItem("semesterSelect")
          );
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getBentukMK() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/bentukMatkulList", config)
        .then((response) => {
          this.$vloading.hide();
          this.bentukItems = response.data.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    getJenisMK() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/jenisMatkulList", config)
        .then((response) => {
          this.$vloading.hide();
          this.jenisItems = response.data.data;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .delete(
          "/api/auth/destroyMataKuliah/" + this.editedItem.kode_mata_kuliah,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "success",
            text: this.$t("notif.hapus"),
          });
          this.close();
          this.getMataKuliah(parseInt(localStorage.getItem("semesterSelect")));
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          // UPDATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("kurikulum_id", this.semesterSelect);
          formData.set("bentuk_mk_id", this.editedItem.bentuk_mk_id);
          formData.set("jenis_mk_id", this.editedItem.jenis_mk_id);
          formData.set("kode_mata_kuliah", this.editedItem.kode_mata_kuliah);
          formData.set(
            "nama_mata_kuliah_id",
            this.editedItem.nama_mata_kuliah_id
          );
          formData.set(
            "nama_mata_kuliah_en",
            this.editedItem.nama_mata_kuliah_en
          );
          formData.set("sks", this.editedItem.sks);
          formData.set("nilai_minimum", this.editedItem.nilai_minimum);
          formData.set("tahun", this.editedItem.tahun);
          formData.set(
            "kategori_mata_kuliah",
            this.editedItem.kategori_mata_kuliah
          );
          this.axios
            .post(
              "/api/auth/saveMataKuliah/" + this.editedItem.kode_mata_kuliah,
              formData,
              config
            )
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.ubah"),
              });
              this.close();
              this.getMataKuliah(
                parseInt(localStorage.getItem("semesterSelect"))
              );
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        } else {
          // CREATE DATA
          this.$vloading.show();
          let config = {
            headers: {
              Authorization: "Bearer " + this.user.data.token,
            },
          };
          let formData = new FormData();
          formData.set("kurikulum_id", this.semesterSelect);
          formData.set("bentuk_mk_id", this.editedItem.bentuk_mk_id);
          formData.set("jenis_mk_id", this.editedItem.jenis_mk_id);
          formData.set("kode_mata_kuliah", this.editedItem.kode_mata_kuliah);
          formData.set(
            "nama_mata_kuliah_id",
            this.editedItem.nama_mata_kuliah_id
          );
          formData.set(
            "nama_mata_kuliah_en",
            this.editedItem.nama_mata_kuliah_en
          );
          formData.set("sks", this.editedItem.sks);
          formData.set("nilai_minimum", this.editedItem.nilai_minimum);
          formData.set("tahun", this.editedItem.tahun);
          formData.set(
            "kategori_mata_kuliah",
            this.editedItem.kategori_mata_kuliah
          );
          this.axios
            .post("/api/auth/saveMataKuliah", formData, config)
            .then(() => {
              this.$vloading.hide();
              this.setAlert({
                status: true,
                color: "success",
                text: this.$t("notif.tambah"),
              });
              this.close();
              this.getMataKuliah(
                parseInt(localStorage.getItem("semesterSelect"))
              );
            })
            .catch((error) => {
              this.$vloading.hide();
              let responses = error.response;
              console.log(responses);
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.message,
              });
            });
        }
        this.closes();
      }
    },

    closes() {
      this.$emit("closed", false);
    },

    koor(item) {
      this.getDosen(item.kode_mata_kuliah);
      this.editedIndex = this.datas.indexOf(item);
      this.koorItem = Object.assign({}, item);
    },

    closeKoor() {
      this.dialogKoor = false;
      this.$nextTick(() => {
        this.koorItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    saveKoor(koorItem) {
      if (this.$refs.form.validate()) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.token,
          },
        };
        let formData = new FormData();
        formData.set("koordinator", koorItem.koordinator);
        this.axios
          .post(
            "/api/auth/updateKoordinator/" + koorItem.kode_mata_kuliah,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "success",
              text: this.$t("notif.ubah"),
            });
            this.closeKoor();
            this.getMataKuliah(
              parseInt(localStorage.getItem("semesterSelect"))
            );
          })
          .catch((error) => {
            this.$vloading.hide();
            let responses = error.response;
            console.log(responses);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.message,
            });
          });
      }
    },

    getDosen(id) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
        },
      };
      this.axios
        .get("/api/auth/getMkPengampuByMk/" + id, config)
        .then((response) => {
          this.$vloading.hide();
          this.dosenItems = response.data.data;
          this.dialogKoor = true;
        })
        .catch((error) => {
          this.$vloading.hide();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.message,
          });
          this.close();
          this.closeDelete();
        });
    },

    submitFile() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.token,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.set("kurikulum_id", localStorage.getItem("semesterSelect"));
      formData.set("bentuk_mk_id", "0");
      formData.set("jenis_mk_id", "0");
      formData.set("excel", this.file);
      this.axios
        .post("/api/auth/importMataKuliah", formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Upload berhasil!",
          });
          this.dialogExcel = false;
          this.$refs.file.value = null;
          this.getMataKuliah(parseInt(localStorage.getItem("semesterSelect")));
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>